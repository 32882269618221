<button mat-button [matMenuTriggerFor]="optionsMenu">
  <span>File</span>
</button>
<mat-menu #optionsMenu="matMenu">
  <button mat-menu-item (click)="birthLocationsUploader.click()" matTooltipPosition="right"
          matTooltip=".html, .txt">
    <mat-icon>insert_drive_file</mat-icon>
    <span>Open Locations or CSV</span>
  </button>
  <button mat-menu-item (click)="geospatialDataUploader.click()" matTooltipPosition="right"
          matTooltip=".kml, .wkt, .csv, .gpx, .topojson, .geojson">
    <mat-icon>layers</mat-icon>
    <span>Open Geospatial Data</span>
  </button>
  <button mat-menu-item (click)="imageUploader.click()" matTooltipPosition="right"
          matTooltip=".jpg, .jpeg, .png, .gif">
    <mat-icon>image</mat-icon>
    <span>Open Image</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="importMenu">
    <mat-icon>cloud_upload</mat-icon>
    <span>Import</span>
  </button>
  <button mat-menu-item [disabled]="datasetsIsNotOpened$ | async" [matMenuTriggerFor]="exportMenu">
    <mat-icon>cloud_download</mat-icon>
    <span>Export</span>
  </button>
  <button mat-menu-item [disabled]="datasetsIsNotOpened$ | async" (click)="onButtonCloseDatasetClicked()">
    <mat-icon>close</mat-icon>
    <span>Close Dataset</span>
  </button>
</mat-menu>

<mat-menu #importMenu="matMenu">
  <button mat-menu-item (click)="regionsUploader.click()">Regions...</button>
  <button mat-menu-item (click)="datasetUploader.click()">Dataset...</button>
</mat-menu>

<mat-menu #exportMenu="matMenu">
  <button mat-menu-item (click)="onExportRegionsButtonClicked()">Regions...</button>
  <button mat-menu-item (click)="onExportDatasetButtonClicked()">Dataset...</button>
  <button mat-menu-item (click)="onExportGeoJsonButtonClicked()">GeoJSON...</button>
</mat-menu>

<input #birthLocationsUploader hidden type="file" accept=".html, .txt" multiple
       (change)="onFileChange($event)"/>
<input #regionsUploader hidden type="file" accept=".json" (change)="onRegionFileChange($event)"/>
<input #datasetUploader hidden type="file" accept=".json" (change)="onDatasetFileChange($event)"/>
<input #geospatialDataUploader hidden type="file" accept=".kml, .wkt, .csv, .gpx, .topojson, .geojson"
       (change)="onGeospatialDataFileChange($event)"/>
<input #imageUploader hidden type="file" accept="image/*" (change)="onImageFileChange($event)"/>
