import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Region} from '../../models/region';
import {MapService} from '../../map/map.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {debounceTime, map} from 'rxjs/operators';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {DatasetLayer} from '../../models/dataset-layer';
import {colord} from 'colord';
import {indexOf, isEmpty, pull} from 'lodash-es';
import {LoadingService} from '../../shared/loading.service';
import {DialogService} from '../../dialog/dialog.service';
import {ImportedGeospatialDataService} from './geospatial-data-expansion-panel/imported-geospatial-data.service';
import {MapTreeNode} from '../../models/map-tree-node';
import {Image} from '../../models/image';
import {ExportToJsonService} from '../../export/export-to-json.service';
import {SaveAsDialogResult} from '../../dialog/name-edit-dialog/name-edit-dialog.component';
import {saveAs} from 'file-saver-es';
import {NotificationService} from '../../notification/notification.service';
import {HeaderComponent} from '../../header/header.component';

export enum Tab {

  CLUSTERING = 'Clustering',
  GEOSPATIAL_DATA = 'Geospatial Data',
  COUNTIES = 'Counties'
}

@Component({
  selector: 'app-layers-sidebar',
  templateUrl: './layers-sidebar.component.html',
  styleUrls: ['./layers-sidebar.component.scss']
})
export class LayersSidebarComponent implements OnInit, OnDestroy {

  public readonly doNotShowSpinner$ = this.loadingService.loading$.pipe(map(show => !show));

  public readonly logOrForColor = new Map<string, string>([
    ['#ffeda0', '(1, 2]'],
    ['#fed976', '(2, 3]'],
    ['#feb24c', '(3, 4]'],
    ['#fd8d3c', '(4, 5]'],
    ['#fc4e2a', '(5, 6]'],
    ['#e31a1c', '(6, 7]'],
    ['#bd0026', '(7, 8]'],
    ['#800026', '(8, x]'],
  ]);

  public readonly maxClusterRadius = 300;
  public readonly treeControl = new NestedTreeControl<MapTreeNode<any>>(
    node => node instanceof DatasetLayer ? node.childNodes : []
  );
  public readonly dataSource = new MatTreeNestedDataSource<MapTreeNode<any>>();
  public readonly clusteringFormGroup: UntypedFormGroup;
  public colors = new Array<string>();
  public readonly tab = Tab;
  public readonly tabIcons: Record<Tab, string> = {
    [Tab.CLUSTERING]: 'group',
    [Tab.GEOSPATIAL_DATA]: 'layers',
    [Tab.COUNTIES]: 'dashboard'
  };
  public readonly openedTabs = new Set<Tab>([Tab.COUNTIES]);
  public activeTab = Tab.COUNTIES;
  protected readonly Region = Region;
  protected readonly Image = Image;
  private clusteringFormGroupSubscription: Subscription;
  private dataSetSubscription: Subscription;
  private nodeSelectionSubscription: Subscription;
  private selectedRegionDatasetExpandSubscription: Subscription;
  private disablePanelOnNoDatasetLayersSubscription = this.mapService.allDatasetLayersClosed$.subscribe(() =>
    this.closeTab(Tab.CLUSTERING)
  );
  private expandPanelOnNewDatasetLayersSubscription = this.mapService.firstDatasetLayerImported$.subscribe(() =>
    this.openTab(Tab.CLUSTERING)
  );
  private disablePanelOnNoGeospatialDataSubscription = this.geospatialDataService.allImportedGeospatialDataClosed$.subscribe(() =>
    this.closeTab(Tab.GEOSPATIAL_DATA)
  );
  private expandPanelOnNewGeospatialDataSubscription = this.geospatialDataService.firstGeospatialDataImported$.subscribe(() =>
    this.openTab(Tab.GEOSPATIAL_DATA)
  );

  constructor(private formBuilder: UntypedFormBuilder,
              private dialogService: DialogService,
              private notifyService: NotificationService,
              public mapService: MapService,
              private geospatialDataService: ImportedGeospatialDataService,
              private loadingService: LoadingService,
              private exportToJsonService: ExportToJsonService) {

    this.clusteringFormGroup = this.formBuilder.group({
      radius: [this.mapService.markerClusterOptions.maxClusterRadius],
      filterColorIndex: [-1]
    });
  }

  public readonly hasChild = (_: number, node: MapTreeNode<any>): boolean => {
    return node instanceof DatasetLayer ? !isEmpty(node.childNodes) : false;
  };

  ngOnInit(): void {

    this.dataSetSubscription = this.mapService.dataSetLayers$.subscribe(dataSets => {

      this.dataSource.data = [];
      this.dataSource.data = dataSets;
      this.treeControl.dataNodes = dataSets;
      this.colors = this.getFillColors(dataSets);
    });

    this.nodeSelectionSubscription = this.mapService.dataSetLayers$.subscribe(dataSets => {

      this.dataSource.data = [];
      this.dataSource.data = dataSets;
      this.treeControl.dataNodes = dataSets;
      this.colors = this.getFillColors(dataSets);
    });

    this.clusteringFormGroupSubscription = this.clusteringFormGroup.valueChanges.pipe(
      debounceTime(100),
      map(formValue => ({radius: formValue.radius, filterColor: this.colors[formValue.filterColorIndex]}))
    ).subscribe(formValue => this.mapService.updateClusteringOptions(formValue.radius, formValue.filterColor));

    this.selectedRegionDatasetExpandSubscription = this.mapService.selectedTreeNodes.changed.pipe(
      map(selectionChange => ({
        selectedRegionDatasets: selectionChange.added
          .filter(treeNode => !!treeNode.parentDatasetLayer)
          .map(region => region.parentDatasetLayer)
      }))
    ).subscribe(selectionChangeDrawnPolygons =>

      selectionChangeDrawnPolygons.selectedRegionDatasets.forEach(dataset => this.treeControl.expand(dataset))
    );
  }

  ngOnDestroy(): void {

    this.nodeSelectionSubscription.unsubscribe();
    this.dataSetSubscription.unsubscribe();
    this.clusteringFormGroupSubscription.unsubscribe();
    this.selectedRegionDatasetExpandSubscription.unsubscribe();
    this.expandPanelOnNewDatasetLayersSubscription.unsubscribe();
    this.disablePanelOnNoDatasetLayersSubscription.unsubscribe();
    this.expandPanelOnNewGeospatialDataSubscription.unsubscribe();
    this.disablePanelOnNoGeospatialDataSubscription.unsubscribe();
  }

  public onSmoothRegionButtonClicked(region: Region): void {

    this.mapService.smoothPolygons(region.layer);
  }

  public onRenameButtonClicked(node: MapTreeNode<any>): void {

    if (node instanceof Region) {

      this.mapService.openRegionModalForUpdate(node);

    } else {

      this.dialogService.openNameEditDialog(node.name).subscribe(dialogResult => {

        node.name = dialogResult.name;
        this.dataSource.data = this.dataSource.data.slice();
      });
    }
  }

  public onShowLayerButtonClicked(treeNode: MapTreeNode<any>): void {

    this.mapService.showMapTreeNodeLayer(treeNode);
  }

  public onHideMapTreeNodeClicked(treeNode: MapTreeNode<any>): void {

    this.mapService.hideMapTreeNodeLayer(treeNode);
  }

  public onDeleteTreeNodeButtonClicked(treeNode: MapTreeNode<any>): void {

    if (treeNode instanceof DatasetLayer) {

      this.mapService.deleteDatasetLayer(treeNode);

    } else if (treeNode instanceof MapTreeNode) {

      this.mapService.deleteMapTreeNode(treeNode);
    }
  }

  public onExportTreeNodeButtonClicked(treeNode: MapTreeNode<any>): void {

    if (treeNode instanceof DatasetLayer) {

      const geoJson = this.exportToJsonService.exportDatasetToGeoJson(treeNode);

      this.saveFile(this.defaultFilename(), new Blob(geoJson, HeaderComponent.OPTIONS));

    } else if (treeNode instanceof MapTreeNode) {

      const geoJson = this.exportToJsonService.exportTreeNodeToGeoJson(treeNode);

      this.saveFile(this.defaultFilename(), new Blob(geoJson, HeaderComponent.OPTIONS));
    }
  }

  public logOrTitleForColor(color: string, colorTitles: Map<string, string>): string {

    return Array.from(colorTitles.entries())
      .filter(([mapColor,]) => colord(mapColor).isEqual(colord(color)))
      .map(([, title]) => title)
      .find(Boolean);
  }

  public onButtonCopyClicked(node: MapTreeNode<any>): void {

    if (!(node instanceof Region)) {

      throw new Error("Not Implemented");
    }

    this.mapService.copyRegion(node);
  }

  public onDisplayAllRegionsButtonClicked(): void {

    this.mapService.getRegions()
      .filter(region => !region.displaying)
      .forEach(region => this.mapService.showMapTreeNodeLayer(region));
  }

  public onHideAllRegionsButtonClicked(): void {

    this.mapService.getRegions()
      .filter(region => region.displaying)
      .forEach(region => this.mapService.hideMapTreeNodeLayer(region));
  }

  public onCreateNewLayerButtonClicked(): void {

    this.dialogService.openNameEditDialog('New Layer').subscribe(dialogResult => {

      const dataSetLayer = this.mapService.addBirthLocations(dialogResult.name);
      this.mapService.selectedTreeNodes.setSelection(dataSetLayer);
    });
  }

  public onTabClick(tab: Tab): void {

    this.activeTab = tab;
  }

  public onClusteringCheckboxChanged(event: Event): void {

    const checked = (<HTMLInputElement>event.target).checked;

    if (checked) {

      this.mapService.enableClustering();

    } else {

      this.mapService.disableClustering();
    }
  }

  private saveFile(filename: string, fileBlob: Blob): void {

    this.dialogService.openNameEditDialog(filename).subscribe((dialogResult: SaveAsDialogResult) => {

      saveAs(fileBlob, dialogResult.name + '.json');
      this.notifyService.showSuccess(`${filename} was saved successfully`);
    });
  }

  private defaultFilename(): string {

    const datasetLayers = this.mapService.getDatasetLayers();
    return datasetLayers.length ? datasetLayers[datasetLayers.length - 1].name : '';
  }

  private openTab(tab: Tab): void {

    this.openedTabs.add(tab);
    this.activeTab = tab;
  }

  private closeTab(tab: Tab): void {

    const array = Array.from(this.openedTabs);
    const indexToRemove = indexOf(array, tab);

    if (indexToRemove === -1) {

      return;
    }

    pull(array, tab);

    if (indexToRemove < array.length) {

      this.activeTab = array[indexToRemove];

    } else if (indexToRemove > 0) {

      this.activeTab = array[indexToRemove - 1];

    } else {

      this.activeTab = null;
    }

    this.openedTabs.delete(tab);
  }

  private getFillColors(dataSetLayers: Array<DatasetLayer>): Array<string> {

    const isUnique = (value: any, index: number, self: any[]): boolean => {
      return self.indexOf(value) === index;
    };

    return dataSetLayers
      .flatMap(dataSet => dataSet.birthLocations)
      .map(marker => marker.options.fillColor)
      .filter(isUnique);
  }
}
