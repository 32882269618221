import {Feature, GeometryObject} from 'geojson';
import {Region} from '../region';
import {TypedExportDto, Type} from './typed-export-dto';

export class RegionExportDto {

  constructor(public id: string, public name: string, public polygon: Feature<GeometryObject>) {
  }

  public static of(region: Region): RegionExportDto {

    return new RegionExportDto(region.parentDatasetLayer.name, region.name, region.layer.toGeoJSON());
  }
}

export class RegionTypedExportDto extends TypedExportDto<RegionExportDto> {

  constructor(exportDto: RegionExportDto) {

    super(Type.REGION, exportDto);
  }
}
