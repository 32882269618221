import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import * as L from 'leaflet';
import {FeatureGroup, ImageOverlay, LatLng, Polygon} from 'leaflet';
import {LeafletControlLayersConfig} from '@asymmetrik/ngx-leaflet/lib/layers/control/leaflet-control-layers-config.model';
import {filter, map, pairwise, startWith} from 'rxjs/operators';
import overlap from '@turf/boolean-overlap';
import {Region} from '../models/region';
import {DatasetLayer} from '../models/dataset-layer';
import {SelectionModel} from '@angular/cdk/collections';
import {NotificationService} from '../notification/notification.service';
import {BirthLocationsLayersBuilder} from '../shared/builder/birth-locations-layers-builder';
import {Image} from '../models/image';
import {MapTreeNode} from '../models/map-tree-node';
import * as _ from 'lodash-es';
import {debounce} from 'lodash-es';
import MapUtils from '../shared/utils/map-utils';
import {DialogService} from '../dialog/dialog.service';
import {LocalStorageService} from '../shared/local-storage.service';

enum MapTileProvider {
  OpenStreetMap = 'OpenStreetMap',
  AncestryMapbox = 'AncestryMapbox'
}

export const tileProviders: Record<MapTileProvider, L.TileLayer> = {
  OpenStreetMap: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: 'OpenStreetMap ©',
    minZoom: 2
  }),
  AncestryMapbox: L.tileLayer(
    'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}@2x?access_token={accessToken}', {
      id: 'ancestrymapbox/cji3frio60ede2rofjxhndhpk',
      accessToken: 'pk.eyJ1IjoiYW5jZXN0cnltYXBib3giLCJhIjoiNllqcGhKYyJ9.p9QKjx4kc2E_55jLTmDw0Q',
      attribution: '<a href="https://www.mapbox.com/about/maps/">Mapbox</a> ©',
      tileSize: 512,
      zoomOffset: -1,
      minZoom: 2
    })
};

const markerClusterOptions: L.MarkerClusterGroupOptions = {
  zoomToBoundsOnClick: false,
  spiderfyOnMaxZoom: false,
  showCoverageOnHover: false,
  iconCreateFunction() {
    return L.divIcon({className: 'no-class'});
  },
  maxClusterRadius: 20
};

const regionPolylineOptions: L.PolylineOptions = {
  dashArray: null,
  fillColor: 'orange',
  fillOpacity: 0.4,
  color: 'orange'
};
const combineRegionPolylineOptions: L.PolylineOptions = {
  dashArray: '5,10',
  fillColor: 'yellow',
  fillOpacity: 0.3,
  color: 'orange'
};

export const freezeClusteringAtZoom = 5;

@Injectable({
  providedIn: 'root'
})
export class MapService {

  public combining = new BehaviorSubject(false);
  public readonly clusteringEnabledSubject = new BehaviorSubject<boolean>(false);
  public readonly clusteringEnabled$ = this.clusteringEnabledSubject.asObservable();
  public readonly refreshClusteringSubject = new Subject<true>();
  public readonly layersControl: LeafletControlLayersConfig = {
    overlays: {},
    baseLayers: {
      [MapTileProvider.OpenStreetMap]: tileProviders.OpenStreetMap,
      [MapTileProvider.AncestryMapbox]: tileProviders.AncestryMapbox
    }
  };
  public readonly mapOptions: L.MapOptions = {
    tap: false,
    maxBounds: L.latLngBounds(L.latLng(-90, -720), L.latLng(90, 720)),
    maxBoundsViscosity: 1.0,
    preferCanvas: true,
    doubleClickZoom: false,
    layers: [tileProviders[this.localStorageService.getBaseLayer()] || tileProviders[MapTileProvider.OpenStreetMap]],
    zoom: 2,
    zoomControl: false,
    worldCopyJump: true,
    center: L.latLng(40.866667, 34.566667)
  };
  public readonly markerClusterOptions = markerClusterOptions;
  public readonly birthLocationsFeatureGroup = L.featureGroup(null, {pane: 'canvaser'});
  public readonly coveragesFeatureGroup = L.featureGroup();
  public readonly drawnItems: L.FeatureGroup;
  public readonly drawOptions: L.Control.DrawConstructorOptions;
  public readonly selectedCombiningRegions = new SelectionModel<Region>(true);
  public readonly selectedTreeNodes = new SelectionModel<MapTreeNode<any>>(false);
  public milesPerPixel = 0;
  private readonly selectTreeNodeFn = debounce(
    (event: L.LeafletEvent) => this.selectedTreeNodes.select(this.getMapTreeNode(event.target)),
    100
  );
  private readonly distortableImages: L.FeatureGroup;
  private readonly datasetLayers = new SelectionModel<DatasetLayer>(true);
  public readonly allDatasetLayersClosed$ = this.datasetLayers.changed.pipe(
    map(selectionChange =>
      selectionChange ? selectionChange.source.isEmpty() : this.datasetLayers.isEmpty()
    ),
    startWith(this.datasetLayers.isEmpty()),
    pairwise(),
    filter(([previous, current]) => !previous && current)
  );
  public readonly firstDatasetLayerImported$ = this.datasetLayers.changed.pipe(
    map(selectionChange =>
      selectionChange ? selectionChange.source.hasValue() : this.datasetLayers.hasValue()
    ),
    startWith(this.datasetLayers.hasValue()),
    pairwise(),
    filter(([previous, current]) => !previous && current)
  );
  public readonly dataSetLayers$ = this.datasetLayers.changed.pipe(map(() => this.getDatasetLayers()));
  private leafletMap: L.Map;

  constructor(private ngZone: NgZone,
              private dialogService: DialogService,
              private localStorageService: LocalStorageService,
              private notifyService: NotificationService) {

    this.drawnItems = L.featureGroup().on('click', event => {

      const region = this.getRegion(event.propagatedFrom);

      if (this.combining.getValue()) {

        this.ngZone.run(() => this.selectedCombiningRegions.toggle(region));

      } else {

        this.ngZone.run(() => this.selectedTreeNodes.select(region));
      }
    });

    this.drawOptions = {
      position: 'bottomleft',
      draw: {
        polyline: false,
        polygon: {shapeOptions: regionPolylineOptions},
        rectangle: false,
        circle: false,
        circlemarker: false,
        marker: false
      },
      edit: {
        featureGroup: this.drawnItems
      }
    };

    // @ts-ignore
    this.distortableImages = (L.distortableCollection() as L.FeatureGroup);

    const combineRegionsFn = (region: Region) => {

      const regions = this.selectedCombiningRegions.selected.sort((a, b) =>
        a === region ? -1 : 1
      );

      if (regions.length < 2) {

        this.notifyService.showWarning('Multiple polygons should be selected to perform this operation');

      } else {

        this.combineRegions(regions);
        this.disableCombining();
      }
    };

    this.selectedCombiningRegions.changed.subscribe(selectionChange => {

      selectionChange.removed.forEach(region => region.layer
        .closePopup()
        .unbindPopup()
        .removeEventListener('popupclose')
        .removeEventListener('popupopen')
        .setStyle(regionPolylineOptions)
      );

      selectionChange.added.forEach(region => {
          const combineRegionButtonClickListener = () => combineRegionsFn(region);

          region.layer
            .setStyle(combineRegionPolylineOptions)
            .bindPopup(
              `<button class="combine btn btn-sm btn-outline-secondary">Combine</button>`,
              {
                autoClose: false,
                closeOnClick: false,
                closeOnEscapeKey: false,
                closeButton: false
              }
            )
            .on('popupopen', (event) => event.target.getPopup()
              .getElement()
              .querySelector('.combine')
              .addEventListener('click', combineRegionButtonClickListener)
            )
            .on('popupclose', (event) => event.target.getPopup()
              .getElement()
              .querySelector('.combine')
              .removeEventListener('click', combineRegionButtonClickListener)
            )
            .openPopup();
        }
      );
    });

    this.selectedTreeNodes.changed.subscribe(selectionChangeDrawnPolygons => {
      selectionChangeDrawnPolygons.removed
        .forEach(node => this.leafletMap.whenReady(() => LayerSelectionDecoratorFactory.for(node).deselect(node)));
      selectionChangeDrawnPolygons.added
        .forEach(node => this.leafletMap.whenReady(() => LayerSelectionDecoratorFactory.for(node).select(node)));
    });

    combineLatest([
      this.selectedTreeNodes.changed.pipe(map(value => ({
        addedDatasetLayers: MapUtils.getParentNodes(value.added).filter((node): node is DatasetLayer => node instanceof DatasetLayer),
        removed: MapUtils.getParentNodes(value.removed).filter((node): node is DatasetLayer => node instanceof DatasetLayer)
      }))),
      this.clusteringEnabled$,
      this.refreshClusteringSubject.pipe(startWith(true))
    ]).subscribe(([changedDatasets, clusteringEnabled]) => this.applyClustering(clusteringEnabled, changedDatasets));
  }

  public set map(leafletMap: L.Map) {

    setTimeout(() => this.milesPerPixel = MapUtils.calculateMilesPerPixel(leafletMap, freezeClusteringAtZoom));

    this.leafletMap = leafletMap
      .addLayer(this.birthLocationsFeatureGroup)
      .addLayer(this.coveragesFeatureGroup)
      .addLayer(this.distortableImages)
      .on('baselayerchange', event => this.localStorageService.setBaseLayer(event.name));
  }

  public static labelMarker(center: LatLng, ...labels: string[]): L.Marker {

    return L.marker(center, {
      icon: new L.DivIcon({
        className: 'label-marker',
        html: `<span>${labels.join('<br>')}</span>`
      })
    });
  }

  public openRegionModalForAdd(polygon: L.Polygon): void {

    this.doIfLayerSelected((datasetLayer) => {

        this.dialogService.openNameEditDialog(datasetLayer.name).subscribe(result => {

          this.addRegion(new Region(result.name, polygon, datasetLayer));
        });
      }
    );
  }

  public openRegionModalForUpdate(region: Region): void {

    this.dialogService.openNameEditDialog(region.name).subscribe(result => {

      region.name = result.name;

      this.datasetLayers.changed.next();
    });
  }

  public addRegion(region: Region): void {

    this.doIfLayerSelected(() => this.addRegionLayer(region.parentDatasetLayer, region.name, region.layer));
  }

  public addRegionLayer(dataset: DatasetLayer, regionName: string, ...polygonFeature: L.Polygon[]): void {

    polygonFeature
      .map((layer: L.Polygon) => {

        const polygon = new L.Polygon(layer.getLatLngs(), regionPolylineOptions);
        // @ts-ignore
        polygon.editing = new L.Edit.Poly(polygon);

        return polygon;
      })
      .map(polygon => new Region(regionName, polygon).addOneTimeLayerAddListener(this.selectTreeNodeFn))
      .forEach(region => {

        dataset.addMapTreeNode(region);

        this.datasetLayers.changed.next();

        this.showMapTreeNodeLayer(region);
      });
  }

  public addImage(image: Image): void {

    this.doIfLayerSelected(selectedLayer =>
      this.addImageLayer(selectedLayer, image.name, image.layer)
    );
  }

  public addImageLayer(datasetLayer: DatasetLayer, filename: string, imageOverlay: ImageOverlay): MapTreeNode<any> {

    const image = new Image(filename, imageOverlay, datasetLayer)
      .addLayerSelectListener(this.selectTreeNodeFn)
      //TODO: Could provoke race condition if selection event is not bounced
      .addOneTimeLayerLoadListener(this.selectTreeNodeFn);

    datasetLayer.addMapTreeNode(image);

    this.datasetLayers.changed.next();

    return this.showMapTreeNodeLayer(image);
  }

  public deleteMapTreeNode(mapTreeNode: MapTreeNode<any>): MapTreeNode<any> {

    this.selectedTreeNodes.deselect(mapTreeNode);

    this.hideMapTreeNodeLayer(mapTreeNode)
      .parentDatasetLayer.removeMapTreeNode(mapTreeNode);

    this.datasetLayers.changed.next();

    return mapTreeNode;
  }

  public updateClusteringOptions(radius: number, filterColor: string): void {

    this.markerClusterOptions.maxClusterRadius = radius;

    this.getDatasetLayers().forEach(dataSet => {

      const displaying = dataSet.displaying;
      const clustering = dataSet.clustering;

      dataSet
        .removeClusteringFrom(this.coveragesFeatureGroup)
        .removeFrom(this.birthLocationsFeatureGroup)
        .updateLayers(
          new BirthLocationsLayersBuilder()
            .setBirthLocations(dataSet.birthLocations)
            .setMarkerClusterOptions(this.markerClusterOptions)
            .setFilterColor(filterColor)
            .setDblClickListener(polygon => {
              const coveragePolygonClone = MapUtils.clonePolygon(polygon);
              this.ngZone.run(() => this.openRegionModalForAdd(coveragePolygonClone));
            })
            .build()
        );

      if (displaying) {

        dataSet.addTo(this.birthLocationsFeatureGroup);
      }

      if (clustering) {

        dataSet.addClusteringTo(this.coveragesFeatureGroup).buildCoverages();
      }
    });
  }

  public addBirthLocations(layerName: string, birthLocationMarkers: Array<L.CircleMarker> = []): DatasetLayer {

    const dataSetLayer = new DatasetLayer(layerName, birthLocationMarkers, this.clusteringEnabledSubject.getValue());

    const updatedDataSet = dataSetLayer.updateLayers(
      new BirthLocationsLayersBuilder()
        .setBirthLocations(dataSetLayer.birthLocations)
        .setMarkerClusterOptions(this.markerClusterOptions)
        .setDblClickListener(polygon => {
          const coveragePolygonClone = MapUtils.clonePolygon(polygon);
          this.ngZone.run(() => this.openRegionModalForAdd(coveragePolygonClone));
        })
        .build()
    );

    this.datasetLayers.select(updatedDataSet);

    return updatedDataSet;
  }

  public smoothPolygons(...polygons: L.Polygon[]): Array<L.Polygon> {

    return MapUtils.smoothPolygons(...polygons);
  }

  public enableClustering(): void {

    this.clusteringEnabledSubject.next(true);
  }

  public disableClustering(): void {

    this.clusteringEnabledSubject.next(false);
  }

  public deleteDatasetLayers(): void {

    this.getDatasetLayers().forEach(dataset => this.deleteDatasetLayer(dataset));
  }

  public deleteDatasetLayer(datasetLayer: DatasetLayer): void {

    datasetLayer
      .removeClusteringFrom(this.coveragesFeatureGroup)
      .removeFrom(this.birthLocationsFeatureGroup)
      .childNodes
      .map(mapTreeNode => {

        this.selectedTreeNodes.deselect(mapTreeNode);

        return this.hideMapTreeNodeLayer(mapTreeNode);
      })
      .forEach(mapTreeNode => {

        mapTreeNode.parentDatasetLayer.removeMapTreeNode(mapTreeNode);

        this.datasetLayers.changed.next();
      });

    this.selectedTreeNodes.deselect(datasetLayer);
    this.datasetLayers.deselect(datasetLayer);
  }

  public findOrCreateDataset(name: string): DatasetLayer {

    const existentDataSet = _.find(this.getDatasetLayers(), dataset => dataset.name === name);

    return existentDataSet || this.addBirthLocations(name);
  }

  public getRegion(polygon: L.Polygon): Region {

    return _.find(this.getRegions(), region => region.layer === polygon);
  }

  public getRegions(): Array<Region> {

    return this.getDatasetLayers().flatMap(datasetLayer => datasetLayer.getRegions());
  }

  public getMapTreeNode(layer: L.Layer): MapTreeNode<any> {

    //TODO: Algorithm will not work in a case of having more then one level of nesting
    const childNodes = this.getDatasetLayers()
      .flatMap(datasetLayer => datasetLayer.childNodes);

    return _.find(childNodes, image => image.layer === layer);
  }

  public getDatasetLayers(): Array<DatasetLayer> {

    return this.datasetLayers.selected;
  }

  public showMapTreeNodeLayer(mapTreeNode: MapTreeNode<any>): MapTreeNode<any> {

    switch (mapTreeNode.constructor) {
      case DatasetLayer: {
        mapTreeNode.addTo(this.birthLocationsFeatureGroup);
        break;
      }
      case Region: {
        mapTreeNode.addTo(this.drawnItems);
        break;
      }
      case Image: {
        mapTreeNode.addTo(this.distortableImages);
        break;
      }
    }

    this.refreshClusteringSubject.next();

    return mapTreeNode;
  }

  public hideMapTreeNodeLayer(mapTreeNode: MapTreeNode<any>): MapTreeNode<any> {

    switch (mapTreeNode.constructor) {
      case DatasetLayer:
        return mapTreeNode.removeFrom(this.birthLocationsFeatureGroup);
      case Region:
        return mapTreeNode.removeFrom(this.drawnItems);
      case Image:
        return mapTreeNode.removeFrom(this.distortableImages);
    }
  }

  public copyRegion(region: Region): void {

    this.addRegionLayer(region.parentDatasetLayer, region.name, MapUtils.clonePolygon(region.layer));
  }

  public zoomToCounty(countyPolygon: Polygon): void {

    this.leafletMap.setView(countyPolygon.getBounds().getCenter(), 9);
  }

  public hasLayer(countiesFeatureGroup: FeatureGroup): boolean {

    return this.leafletMap.hasLayer(countiesFeatureGroup);
  }

  public addLayer(countiesFeatureGroup: FeatureGroup): L.Map {

    return this.leafletMap.addLayer(countiesFeatureGroup);
  }

  public removeLayer(countiesFeatureGroup: FeatureGroup): L.Map {

    return this.leafletMap.removeLayer(countiesFeatureGroup);
  }

  public combineRegions(regions: Region[]): Region {

    return regions.reduce((previousValue, currentValue) => {

      const polygonsOverlap = overlap(previousValue.layer.toGeoJSON(), currentValue.layer.toGeoJSON());

      previousValue.layer = polygonsOverlap
        ? MapUtils.combineRegionsByUnion(previousValue.layer, currentValue.layer)
        : MapUtils.combineRegionsByConvex(previousValue.layer, currentValue.layer);

      this.deleteMapTreeNode(currentValue);

      return previousValue;
    });
  }

  public enableCombining(): void {

    this.combining.next(true);
  }

  public disableCombining(): void {

    this.combining.next(false);
    this.selectedCombiningRegions.clear();
  }

  private applyClustering(clusteringEnabled: boolean, changedDatasets: {
    addedDatasetLayers: Array<DatasetLayer>;
    removed: Array<DatasetLayer>
  }): void {

    if (clusteringEnabled) {

      const addClusteringIfDisplaying = (dataset: DatasetLayer) => dataset.displaying
        ? dataset.addClusteringTo(this.coveragesFeatureGroup).buildCoverages()
        : dataset;

      changedDatasets.removed.forEach(dataset => dataset.removeClusteringFrom(this.coveragesFeatureGroup));
      changedDatasets.addedDatasetLayers.forEach(dataset => addClusteringIfDisplaying(dataset));

    } else {

      changedDatasets.addedDatasetLayers.forEach(dataset => dataset.removeClusteringFrom(this.coveragesFeatureGroup));
    }
  }

  private doIfLayerSelected(functionToDo: (datasetLayer: DatasetLayer) => void): void {

    this.doIfLayerSelectedOrElse(functionToDo, () => this.notifyService.showWarning(
      'You must select the layer to which you want to add the object.',
      'Layer Was Not Selected'
    ));
  }

  private doIfLayerSelectedOrElse(functionToDo: (treeNode: MapTreeNode<any>) => void,
                                  elseFunctionToDo: () => void): void {

    if (this.selectedTreeNodes.isEmpty()) {

      elseFunctionToDo();

    } else {

      const parentDatasetLayer = MapUtils.getParentNodes(this.selectedTreeNodes.selected).find(Boolean);

      functionToDo(parentDatasetLayer);
    }
  }
}

abstract class LayerSelectionDecorator<T extends MapTreeNode<any>> {

  select(t: T): void {
  };

  deselect(t: T): void {
  };
}

abstract class LayerSelectionDecoratorFactory {

  private static readonly SELECTED_POLYGON_OPTIONS: L.PolylineOptions = {
    fillColor: 'orange',
    fillOpacity: 0.7,
    color: 'orange'
  };

  public static for(node: MapTreeNode<any>): LayerSelectionDecorator<any> {

    switch (node.constructor) {
      case Region:
        return new class implements LayerSelectionDecorator<Region> {
          select = (region: Region) => setTimeout(
            () => region.layer.setStyle(LayerSelectionDecoratorFactory.SELECTED_POLYGON_OPTIONS)
              .bringToFront()
          );

          deselect = (region: Region) => setTimeout(
            () => region.layer.setStyle(regionPolylineOptions)
          );
        };
      case Image:
        return new class implements LayerSelectionDecorator<Image> {
          select = (image: Image) => setTimeout(
            // @ts-ignore
            () => image.layer.select()
          );

          deselect = (image: Image) => setTimeout(
            // @ts-ignore
            () => image.layer.deselect()
          );
        };
      default:
        return new class extends LayerSelectionDecorator<any> {
        };
    }
  }
}
