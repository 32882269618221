import * as L from 'leaflet';
import {
  LayerGroup,
  Map,
  MarkerCluster,
  MarkerClusterGroup,
  MarkerClusterGroupOptions,
  Polygon,
  PolylineOptions
} from 'leaflet';
import 'leaflet.markercluster';
import {of} from 'rxjs';

export class MarkerClusterCoverageGroup extends MarkerClusterGroup {

  private static readonly COVERAGE_POLYLINE_OPTIONS: PolylineOptions = {
    fillColor: 'cyan',
    fillOpacity: 0.4,
    color: 'cyan'
  };

  private readonly dblClickListeners = new Array<(polygon: Polygon) => void>();
  private readonly coveragesGroup = new LayerGroup<Polygon>();

  constructor(options?: MarkerClusterGroupOptions) {

    super(options);

    this.on('animationend', () => {

      this.coveragesGroup.clearLayers();

      (this as any)._featureGroup.eachLayer((layer: any) => {

        if (layer instanceof MarkerCluster && layer.getChildCount() > 2) {

          const coveragePolygon = L.polygon((layer as any).getConvexHull(), MarkerClusterCoverageGroup.COVERAGE_POLYLINE_OPTIONS)
            .bindTooltip(() => 'double-click to create region.')
            .on(
              'dblclick',
              event => of(event.target as Polygon).subscribe(polygon => this.dblClickListeners.forEach(listener => listener(polygon)))
            );

          this.coveragesGroup.addLayer(coveragePolygon);
        }
      });
    });
  }

  public onAdd(map: Map): this {

    map.addLayer(this.coveragesGroup);

    return super.onAdd(map);
  }

  public onRemove(map: Map): this {

    map.removeLayer(this.coveragesGroup);

    return super.onRemove(map);
  }

  public fireAnimationEndEvent(): this {

    this.fireEvent('animationend');

    return this;
  }

  public addDblClickListener(listener: (polygon: Polygon) => void): this {

    this.dblClickListeners.push(listener);

    return this;
  }
}
