import {Component} from '@angular/core';
import {ImportedGeospatialData, ImportedGeospatialDataService} from './imported-geospatial-data.service';

@Component({
    selector: 'app-geospatial-data-expansion-panel',
    templateUrl: './geospatial-data-expansion-panel.component.html',
    styleUrls: ['./geospatial-data-expansion-panel.component.scss']
})
export class GeospatialDataExpansionPanelComponent {

    public readonly featureGroupSelectionModel = this.geospatialDataService.featureGroupSelectionModel;

    constructor(public geospatialDataService: ImportedGeospatialDataService) {
    }

    public onButtonRemoveClicked(featureGroup: ImportedGeospatialData): void {

        this.geospatialDataService.remove(featureGroup);
    }

    public onPropertiesKeysSelectionChange(
        [featureGroup, propertiesKeys]: [featureGroup: ImportedGeospatialData, propertiesKeys: Array<string>]
    ): void {

        this.geospatialDataService.displayProperties(featureGroup, propertiesKeys);
    }
}
