import {Injectable} from '@angular/core';
import {
  CsvFilePreviewDialogComponent,
  ParsedCsvFile
} from '../shared/csv-file-preview-dialog/csv-file-preview-dialog.component';
import {NameEditDialogComponent, Result, SaveAsDialogResult} from './name-edit-dialog/name-edit-dialog.component';
import {filter} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) {
  }

  public openCsvFileDialog(parsedCsvFile: ParsedCsvFile): Observable<any> {

    return this.dialog.open(CsvFilePreviewDialogComponent, {
      data: parsedCsvFile, hasBackdrop: false,
      panelClass: ['no-padding', 'resizable-mat-dialog-panel']
    }).afterClosed();
  }

  public openNameEditDialog(filename: string): Observable<SaveAsDialogResult> {

    return this.dialog.open(NameEditDialogComponent, {
      minWidth: 360,
      data: {name: filename}
    }).afterClosed().pipe(
      filter((result: SaveAsDialogResult) => Result.SAVE === result?.result)
    );
  }
}
