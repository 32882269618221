export abstract class TypedExportDto<T> {

  constructor(public type: Type, public exportDto: T) {
  }
}

export enum Type {

  DATASET = 'dataset',
  REGION = 'region',
  IMAGE = 'image'
}
