import * as L from 'leaflet';
import {DatasetLayer} from '../../models/dataset-layer';
import {Mapper} from './dataset-typed-export-dto-to-dataset-mapper';
import {Region} from '../../models/region';
import {RegionExportDto} from '../../models/export/region-export-dto';
import {DatasetExportDto} from '../../models/export/dataset-export-dto';

export class DatasetDtoToDatasetMapper implements Mapper<DatasetExportDto, DatasetLayer> {

  public map(datasetDto: DatasetExportDto): DatasetLayer {

    const birthLocations = datasetDto.birthLocations.map(feature =>
      new PointFeatureToCircleMarkerMapper().map(feature)
    );
    const dataset = new DatasetLayer(datasetDto.name, birthLocations, false);

    datasetDto.regions
      .map(region => new RegionExportDtoToRegionMapper().map(region))
      .forEach(region => dataset.addMapTreeNode(region));

    return dataset;
  }
}

export class PointFeatureToCircleMarkerMapper implements Mapper<any, L.CircleMarker> {

  public map(pointFeature): L.CircleMarker {

    return L.geoJSON(pointFeature, {
      pointToLayer(feature, latlng) {
        return L.circleMarker(latlng, feature.properties);
      }
    }).getLayers().map(layer => layer as L.CircleMarker).find(Boolean);
  }
}

export class RegionExportDtoToRegionMapper implements Mapper<RegionExportDto, Region> {

  public map(regionExportDto: RegionExportDto): Region {

    const polygon = L.geoJSON(regionExportDto.polygon).getLayers()
      .filter(layer => layer instanceof L.Polygon)
      .map(p => p as L.Polygon)
      .find(Boolean);

    return new Region(regionExportDto.name, polygon);
  }
}
