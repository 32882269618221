<div class="card border-0 rounded-0">
  <div class="card-header d-flex flex-row justify-content-between align-items-center bg-transparent">
    {{csvFile.name}}
    <button mat-icon-button (click)="onCloseButtonClicked(csvFile)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="card-body">
    <div class="overflow-auto">
      <table matSort [matSortDisabled]="true" (matSortChange)="sortedCsvRows = sort($event, csvFile.csvRows?.slice())"
             class="table table-light">
        <thead *ngIf="csvFile.headers">
        <tr>
          <th scope="col" [mat-sort-header]="i.toString()"
              *ngFor="let header of csvFile.headers; let i = index">{{header}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let csvRow of sortedCsvRows | slice:0:10">
          <td *ngFor="let value of csvRow" class="small">{{value}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr *ngIf="sortedCsvRows?.length else noData">
          <td [colSpan]="(sortedCsvRows | slice:0:10)[0].length">
            <button class="btn btn-link small p-0"
                    (click)="onShowAllCsvRowsButtonClicked(csvFile)">
              Show More...
            </button>
          </td>
        </tr>
        <ng-template #noData>
          <tr>
            <td class="text-muted small">
              File is missing
            </td>
          </tr>
        </ng-template>
        </tfoot>
      </table>
    </div>
  </div>
</div>
