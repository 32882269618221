import {Injectable} from '@angular/core';
import {MapService} from '../map/map.service';
import {RegionExportDto} from '../models/export/region-export-dto';
import {DatasetLayer} from '../models/dataset-layer';
import {DatasetTypedExportDto} from '../models/export/dataset-typed-export-dto';
import {featureGroup, Polygon} from 'leaflet';
import {MapTreeNode} from '../models/map-tree-node';
import {Region} from '../models/region';
import MapUtils from '../shared/utils/map-utils';

@Injectable({
  providedIn: 'root'
})
export class ExportToJsonService {

  constructor(public mapService: MapService) {
  }

  private static setDatasetNameProperty(datasetLayer: DatasetLayer, layer: Polygon) {

    layer.feature.properties = {
      ...layer.feature.properties,
      ...(datasetLayer && {DATASET_NAME: datasetLayer.name})
    }

    return layer;
  }

  private static setNameProperty(region: Region, layer: L.Polygon) {

    layer.feature.properties = {
      ...layer.feature.properties,
      NAME: region.name
    }

    return layer;
  }

  public exportDataset(): Array<string> {

    const dataSets = this.mapService.getDatasetLayers()
      .filter(node => node instanceof DatasetLayer)
      .map(DatasetTypedExportDto.of);
    return [JSON.stringify(dataSets)];
  }

  public exportRegions(): Array<string> {

    const regions = this.mapService.getRegions().map(RegionExportDto.of);

    return [JSON.stringify(regions)];
  }

  public exportDatasetToGeoJson(datasetLayer: DatasetLayer): Array<string> {

    const regionLayers = datasetLayer.getRegions()
      .map(region => {

        const polygon = MapUtils.mapToFeaturedLayer(region.layer);

        ExportToJsonService.setDatasetNameProperty(datasetLayer, polygon);
        ExportToJsonService.setNameProperty(region, polygon);

        return polygon;
      });

    const geoJSON = featureGroup(regionLayers).toGeoJSON();

    return [JSON.stringify(geoJSON)];
  }

  public exportTreeNodeToGeoJson(treeNode: MapTreeNode<Polygon>): Array<string> {

    const polygon = MapUtils.mapToFeaturedLayer(treeNode.layer);

    ExportToJsonService.setDatasetNameProperty(treeNode.parentDatasetLayer, polygon);
    ExportToJsonService.setNameProperty(treeNode, polygon);

    const geoJSON = polygon.toGeoJSON();

    return [JSON.stringify(geoJSON)];
  }

  public exportToGeoJson(): Array<string> {

    const featuredPolygons = this.mapService.getDatasetLayers()
      .flatMap(dataset =>
        dataset.getRegions().map(region => [dataset, region])
      )
      .map(([datasetLayer, region]: [DatasetLayer, Region]) => {

          const polygon = MapUtils.mapToFeaturedLayer(region.layer);

          ExportToJsonService.setDatasetNameProperty(datasetLayer, polygon);
          ExportToJsonService.setNameProperty(region, polygon);

          return polygon;
        }
      );

    const polygonsFeatureGroup = featureGroup(featuredPolygons).toGeoJSON();

    return [JSON.stringify(polygonsFeatureGroup)];
  }
}
