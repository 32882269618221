import * as acorn from 'acorn';
import * as walk from 'acorn-walk';
import {
  ArrayExpression,
  CallExpression,
  Expression,
  Identifier,
  MemberExpression,
  ObjectExpression,
  Pattern,
  PrivateIdentifier,
  Property,
  SpreadElement,
  Super,
  UnaryExpression
} from 'estree';
import {CircleMarker, LatLng} from 'leaflet';


export class CommunityBirthLocationsParser {

  private static parseDocument(document): acorn.Node {

    const scriptElements = new DOMParser()
      .parseFromString(document, 'text/html')
      .getElementsByTagName('script');

    const scriptText = Array.from(scriptElements)
      .map(el => el.textContent)
      .join('\r\n');

    return acorn.parse(scriptText, {ecmaVersion: 2020});
  }

  public parse(document): Map<string, Array<CircleMarker>> {

    const documentNode = CommunityBirthLocationsParser.parseDocument(document);

    return this.findBirthLocationLayers(documentNode);
  }

  private findBirthLocationLayers(documentNode: acorn.Node): Map<string, Array<CircleMarker>> {

    const findOverlayProperties = (properties: Array<Property | SpreadElement>): Array<ObjectExpression> => {

      return properties
        .filter(prop => prop.type === 'Property')
        .filter((prop: Property) => prop.key.type === 'Identifier')
        .filter((prop: Property) => (prop.key as Identifier).name === 'overlays')
        .filter((overlaysProperty: Property) => overlaysProperty.value.type === 'ObjectExpression')
        .map((overlaysProperty: Property) => overlaysProperty.value as ObjectExpression);
    };

    const isAddToCallee = (callExpression: Expression | Super): boolean =>
      callExpression.type === 'MemberExpression'
      && callExpression.property.type === 'Identifier'
      && callExpression.property.name === 'addTo';

    const isAddToArgs = (args: Array<Expression | SpreadElement>): boolean =>
      args[0].type === 'Identifier';

    const isCircleMarkerCallee = (callExpression: Expression | Super): boolean =>
      callExpression.type === 'MemberExpression'
      && callExpression.property.type === 'Identifier'
      && callExpression.property.name === 'circleMarker';

    const isCircleMarkerArgs = (args: Array<Expression | SpreadElement>): boolean =>
      args[0].type === 'ArrayExpression' && args[1].type === 'ObjectExpression';

    const extractLayerName = (objectExpression: Identifier): string => {

      return objectExpression.name;
    };

    const visitExpression = (expr: Expression | SpreadElement | PrivateIdentifier | Pattern): any => {

      if (expr.type === 'Identifier') {

        return expr.name;

      } else if (expr.type === 'UnaryExpression') {

        return visitUnaryExpression(expr);

      } else if (expr.type === 'Literal') {

        return expr.value;
      }
    };

    const visitUnaryExpression = (exp: UnaryExpression) => {

      switch (exp.operator) {
        case '-':
          return -visitExpression(exp.argument);
        case '+':
          return +visitExpression(exp.argument);
      }
    };

    const extractMarkerCoordinates = (arrayExpression: ArrayExpression): LatLng => {

      const points = arrayExpression.elements.map(element => visitExpression(element));

      return new LatLng(points[0], points[1]);
    };

    const visitObjectExpression = (objectExpression: ObjectExpression, swapKeyValue = false): any => {

      const mappingFunction = swapKeyValue
        ? (property: Property) => [visitExpression(property.value), visitExpression(property.key)]
        : (property: Property) => [visitExpression(property.key), visitExpression(property.value)];

      return objectExpression.properties
        .filter(value => value.type === 'Property')
        .map(mappingFunction)
        .reduce((acc, elem: [any, any]) => {
          acc[elem[0].toString()] = elem[1];
          return acc;
        }, {});
    };

    const cleanMarker = (marker: L.CircleMarker): L.CircleMarker => {

      marker.options.stroke = null;
      marker.options.weight = null;
      marker.options.color = null;
      marker.options.bubblingMouseEvents = null;
      return marker;
    };

    const addLocationToMap = (locationsMap: Map<string, Array<CircleMarker>>, layerName: string, point): void => {

      if (locationsMap.has(layerName)) {

        locationsMap.get(layerName).push(point);

      } else {

        locationsMap.set(layerName, [point]);
      }
    };

    const birthLocations = new Map<string, Array<CircleMarker>>();
    const layerNameCommunityIds = new Array<[string, any]>();

    walk.simple(documentNode, {
      CallExpression(node: any) {

        const callExpression = node as CallExpression;

        if (isAddToCallee(callExpression.callee) && isAddToArgs(callExpression.arguments)) {

          const callee = callExpression.callee as MemberExpression;

          if (callee.object.type === 'CallExpression') {

            const calleeObject = callee.object;

            if (isCircleMarkerCallee(calleeObject.callee) && isCircleMarkerArgs(calleeObject.arguments)) {

              const latLng = extractMarkerCoordinates(calleeObject.arguments[0] as ArrayExpression);
              const options = visitObjectExpression(calleeObject.arguments[1] as ObjectExpression);
              const layerName = extractLayerName(callExpression.arguments[0] as Identifier);

              addLocationToMap(birthLocations, layerName, cleanMarker(new CircleMarker(latLng, options)));
            }
          }
        }
      },
      ObjectExpression(node: any) {

        findOverlayProperties((node as ObjectExpression).properties)
          .map(overlayProperty => visitObjectExpression(overlayProperty, true))
          .map(overlayObject => Object.entries(overlayObject))
          .forEach(overlayObjectEntries => layerNameCommunityIds.push(...overlayObjectEntries));
      }
    });

    return this.assignCommunityIdsToBirthLocationsLayers(birthLocations, new Map(layerNameCommunityIds));
  }

  private assignCommunityIdsToBirthLocationsLayers(layerBirthLocations: Map<string, Array<CircleMarker>>,
                                                   communityIds: Map<string, string>): Map<string, Array<CircleMarker>> {

    const communityBirthLocations = new Map<string, Array<CircleMarker>>();

    layerBirthLocations.forEach((value, layerName) => {

      if (communityIds.has(layerName)) {

        const communityId = communityIds.get(layerName);
        communityBirthLocations.set(communityId, value);
      } else {

        communityBirthLocations.set(layerName, value);
      }
    });

    return communityBirthLocations;
  }
}
