<div class="modal-content h-100">
  <div class="modal-header bg-info px-2" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h6 class="modal-title">{{data.name}}</h6>
    <button type="button" class="btn-close" [matDialogClose]="[]"></button>
  </div>
  <div class="modal-body overflow-auto" style="height: 75vh">
    <table mat-table matSort [dataSource]="dataSource" class="w-100">
      <ng-container [matColumnDef]="columnKey" *ngFor="let columnKey of displayedColumnKeys; let i = index">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-capitalize">
          {{displayedColumnValues[i]}}
        </th>
        <td mat-cell *matCellDef="let element">
          {{element[columnKey]}}
        </td>
      </ng-container>

      <tr mat-header-row class="bg-light" *matHeaderRowDef="displayedColumnKeys; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnKeys"></tr>
    </table>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-auto">
        <form [formGroup]="tablePropertiesFormGroup">
          <mat-form-field class="no-padding">
            <mat-label>Search</mat-label>
            <input matInput formControlName="searchText">
          </mat-form-field>
        </form>
      </div>
      <div class="col">
        <mat-paginator [pageSizeOptions]="[15, 30, 60]" showFirstLastButtons aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
