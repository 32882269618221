<div class="card">
  <div class="card-header d-flex flex-row flex-nowrap">
    <h6 class="m-0 text-truncate" [title]="_featureGroup.filename">
      {{_featureGroup.filename}}
    </h6>
    <button (click)="removeImportedFeatureGroup.emit(_featureGroup)" type="button" class="btn-close" aria-label="Close">
    </button>
  </div>
  <div class="card-body overflow-auto" style="height: 192px">
    <mat-chip-listbox selectable multiple>
      <mat-chip-option *ngFor="let propertiesKey of _featureGroup.propertiesKeys"
                [value]="propertiesKey">
        {{propertiesKey}}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
