<header class="navbar navbar-light sticky-top bg-light flex-md-nowrap p-0 border-bottom">
    <app-header></app-header>
</header>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-auto col-md-auto col-sm-12 border-end border-bottom p-0">
            <mat-button-toggle class="no-padding rounded-0" data-bs-toggle="collapse" data-bs-target="#leftSidebar"
                               aria-expanded="true" aria-controls="leftSidebar" checked="true" matTooltip="Project">
                <mat-icon>folder</mat-icon>
            </mat-button-toggle>
        </div>
        <div class="col-lg-3 col-md-3 mw-sm-mw-12 left-sidebar position-relative p-0 collapse show" id="leftSidebar"
             mwlResizable
             [ngStyle]="leftSidebarStyle" (resizeEnd)="onLeftSidebarResizeEnd($event)">
            <app-layers-sidebar></app-layers-sidebar>
            <div class="resize-handle-right top-0" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
        </div>
        <main class="col-lg col-md map-container p-0">
            <app-map></app-map>
        </main>
        <div class="col-lg-auto col-md-auto col-sm-12 border-start position-relative p-0">
            <div [class.show]="!csvFilesSidebarCollapsed"
                 class="right-sidebar position-absolute collapse bg-light min-width-5px" id="rightSidebar"
                 mwlResizable [ngStyle]="rightSidebarStyle" (resizeEnd)="onRightSidebarResizeEnd($event)">
                <div class="resize-handle-left" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
                <app-csv-files-sidebar></app-csv-files-sidebar>
            </div>
            <mat-button-toggle [checked]="!csvFilesSidebarCollapsed"
                               (click)="csvFilesSidebarCollapsed = !csvFilesSidebarCollapsed"
                               class="no-padding rounded-0" matTooltip="Files">
                <mat-icon>list_alt</mat-icon>
            </mat-button-toggle>
        </div>
    </div>
</div>
