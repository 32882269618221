import {Component} from '@angular/core';
import {MapService} from './map.service';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster.freezable';
import 'leaflet-draw';
import 'leaflet-easybutton';
import 'leaflet-draw-drag';
import 'leaflet-canvas-markers';
import 'leaflet.layergroup.collision';
import 'leaflet-toolbar';
import 'leaflet-distortableimage';

const iconRetinaUrl = './assets/marker-icon-2x.png';
const iconUrl = './assets/marker-icon.png';
const shadowUrl = './assets/marker-shadow.png';
L.Marker.prototype.options.icon = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
// @ts-ignore
L.Draw.Polyline.prototype.options.shapeOptions.interactive = true;
// @ts-ignore
L.Draw.Polygon.prototype.options.shapeOptions.interactive = true;
// @ts-ignore
L.Draw.Rectangle.prototype.options.shapeOptions.interactive = true;
// @ts-ignore
L.Draw.CircleMarker.prototype.options.interactive = true;
// @ts-ignore
L.Draw.Circle.prototype.options.shapeOptions.interactive = true;
// @ts-ignore
// https://github.com/Leaflet/Leaflet.draw/issues/789
// https://github.com/Leaflet/Leaflet.draw/issues/695
L.Draw.Polyline.prototype._onTouch = L.Util.falseFn;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {

  private enableCombiningButton: L.Control.EasyButton;

  constructor(public mapService: MapService) {

    const enableCombining = () => {

      this.mapService.enableCombining();
    };

    const disableCombining = () => {

      this.mapService.disableCombining();
    };

    this.enableCombiningButton = L.easyButton({
      position: 'bottomleft',
      leafletClasses: true,
      states: [
        {
          stateName: 'enable-combine-polygons',
          icon: 'fa-object-group',
          title: 'Combine Polygons',
          onClick() {
            enableCombining();
          }
        },
        {
          stateName: 'disable-combine-polygons',
          icon: 'fa-times',
          onClick() {
            disableCombining();
          },
          title: 'Disable'
        }]
    });

    this.mapService.combining.subscribe(combining => {

      if (combining) {

        this.enableCombiningButton.state('disable-combine-polygons');
      } else {

        this.enableCombiningButton.state('enable-combine-polygons');
      }
    });
  }

  public onMapReady(map: L.Map): void {

    // Need to disable it here because leaflet-distortableimage plugin overrides this setting
    map.doubleClickZoom.disable();

    const mapDiv = document.getElementById('map') as HTMLElement;

    new ResizeObserver(() => map.invalidateSize())
      .observe(mapDiv);

    this.mapService.map = map
      .addControl(
        this.enableCombiningButton
      ).addControl(
        L.control.zoom({position: 'bottomright'})
      ).addControl(
        L.control.layers(
          this.mapService.layersControl.baseLayers,
          this.mapService.layersControl.overlays,
          {position: 'topleft'}
        )
      );
  }

  public onDrawCreated(createdEvent: L.DrawEvents.Created): void {

    if (createdEvent.layer instanceof L.Polygon) {

      this.mapService.openRegionModalForAdd(createdEvent.layer);
    }
  }

  public onDrawDeleted(deletedEvent: L.DrawEvents.Deleted): void {

    deletedEvent.layers.eachLayer(layer => this.deleteRegionPolygon(layer));
  }

  private deleteRegionPolygon(layer: L.Layer): void {

    if (layer instanceof L.Polygon) {

      const region = this.mapService.getRegion(layer);
      this.mapService.deleteMapTreeNode(region);
    }
  }
}
