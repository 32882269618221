import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {LayersSidebarComponent} from './sidebar/layers-sidebar/layers-sidebar.component';
import {ModalComponent} from './shared/modal/modal.component';
import {MapComponent} from './map/map.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {ToastrModule} from 'ngx-toastr';
import {MemoizePipe} from './pipes/memoize.pipe';
import {HeaderComponent} from './header/header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  CountyExpansionPanelComponent
} from './sidebar/layers-sidebar/county-expansion-panel/county-expansion-panel.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {NameEditDialogComponent} from './dialog/name-edit-dialog/name-edit-dialog.component';
import {ResizableModule} from 'angular-resizable-element';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {CsvFilesSidebarComponent} from './sidebar/csv-files-sidebar/csv-files-sidebar.component';
import {MatSortModule} from '@angular/material/sort';
import {CsvFilePreviewComponent} from './sidebar/csv-files-sidebar/csv-file-preview/csv-file-preview.component';
import {CsvFilePreviewDialogComponent} from './shared/csv-file-preview-dialog/csv-file-preview-dialog.component';
import {TableVirtualScrollModule} from 'ng-table-virtual-scroll';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
  GeospatialDataExpansionPanelComponent
} from './sidebar/layers-sidebar/geospatial-data-expansion-panel/geospatial-data-expansion-panel.component';
import {
  ImportedFeatureGroupCardComponent
} from './sidebar/layers-sidebar/geospatial-data-expansion-panel/imported-feature-group-card/imported-feature-group-card.component';
import {PixelsMilesPipe} from './pipes/pixels-miles.pipe';
import {InstanceofPipe} from './pipes/instanceof.pipe';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LayersSidebarComponent,
    ModalComponent,
    MapComponent,
    MemoizePipe,
    PixelsMilesPipe,
    CountyExpansionPanelComponent,
    NameEditDialogComponent,
    CsvFilesSidebarComponent,
    CsvFilePreviewComponent,
    CsvFilePreviewDialogComponent,
    GeospatialDataExpansionPanelComponent,
    ImportedFeatureGroupCardComponent,
    InstanceofPipe
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatTreeModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        ResizableModule,
        ToastrModule.forRoot(),
        LeafletModule,
        LeafletDrawModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatInputModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatSortModule,
        ScrollingModule,
        TableVirtualScrollModule,
        MatTableModule,
        MatCheckboxModule,
        DragDropModule,
        MatPaginatorModule,
        MatDividerModule,
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
