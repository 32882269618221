import * as L from 'leaflet';
import {ImageOverlay, LatLng, LeafletEvent} from 'leaflet';
import 'leaflet-toolbar';
import 'leaflet-distortableimage';
import {isNil} from 'lodash-es';

export class DistortableImageOverlayBuilder {

  private static readonly DEFAULT_ACTIONS = [
    // @ts-ignore
    L.DragAction, L.ScaleAction, L.DistortAction, L.RotateAction, L.FreeRotateAction, L.LockAction, L.OpacityAction, L.BorderAction
  ];
  private static readonly DEFAULT_MODE = 'drag'

  private _url!: string;
  private _corners?: LatLng[];
  private _mode?: string;
  private _selectListener?: (event: LeafletEvent) => void;
  private _oneTimeAddListener?: (event: LeafletEvent) => void;
  private _oneTimeLoadListener?: (event: LeafletEvent) => void;

  public setUrl(url: string): DistortableImageOverlayBuilder {

    this._url = url;

    return this;
  }

  public setCorners(corners: any): DistortableImageOverlayBuilder {

    this._corners = corners;

    return this;
  }

  public setMode(mode: string): DistortableImageOverlayBuilder {

    this._mode = mode;

    return this;
  }

  public setSelectListener(listener: (event: LeafletEvent) => void): DistortableImageOverlayBuilder {

    this._selectListener = listener;

    return this;
  }

  public setOneTimeAddListener(listener: (event: LeafletEvent) => void): DistortableImageOverlayBuilder {

    this._oneTimeLoadListener = listener;

    return this;
  }

  public setOneTimeLoadListener(listener: (event: LeafletEvent) => void): DistortableImageOverlayBuilder {

    this._oneTimeLoadListener = listener;

    return this;
  }

  public build(): ImageOverlay & any {

    // @ts-ignore
    const distortableImageOverlay = L.distortableImageOverlay(
      this._url,
      {
        actions: DistortableImageOverlayBuilder.DEFAULT_ACTIONS,
        mode: isNil(this._mode) ? DistortableImageOverlayBuilder.DEFAULT_MODE : this._mode,
        corners: this._corners
      }
    ) as ImageOverlay & any;

    if (!isNil(this._selectListener)) {

      distortableImageOverlay.addEventListener('select', this._selectListener);
    }

    if (!isNil(this._oneTimeAddListener)) {

      distortableImageOverlay.addOneTimeEventListener('add', this._oneTimeAddListener);
    }

    if (!isNil(this._oneTimeLoadListener)) {

      distortableImageOverlay.addOneTimeEventListener('load', this._oneTimeLoadListener);
    }

    return distortableImageOverlay;
  }

  public from(imageOverlay: ImageOverlay & any): DistortableImageOverlayBuilder {

    // @ts-ignore
    this.setUrl(imageOverlay._url);
    // @ts-ignore
    this.setCorners(imageOverlay.getCorners());
    // @ts-ignore
    this.setMode(imageOverlay.editing.getMode());

    return this;
  }
}
