import {DatasetTypedExportDtoToDatasetMapper, Mapper} from './dataset-typed-export-dto-to-dataset-mapper';
import {DatasetExportDto} from '../../models/export/dataset-export-dto';
import {DatasetLayer} from '../../models/dataset-layer';
import {DatasetTypedExportDto} from '../../models/export/dataset-typed-export-dto';
import {TypedExportDto} from '../../models/export/typed-export-dto';
import {DatasetDtoToDatasetMapper} from './dataset-dto-to-dataset-mapper';

export class DatasetDtoToDatasetMapperFactory {

  public for(dataset: DatasetExportDto | DatasetTypedExportDto): Mapper<DatasetExportDto | DatasetTypedExportDto, DatasetLayer> {

    const hasMatchingProperty = Object.keys(
      new class extends TypedExportDto<any> {
      }(null, null)
    ).every(key => dataset.hasOwnProperty(key));

    return hasMatchingProperty
      ? new DatasetTypedExportDtoToDatasetMapper()
      : new DatasetDtoToDatasetMapper();
  }
}
