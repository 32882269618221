import {MapTreeNode} from './map-tree-node';
import {Polygon} from 'leaflet';
import {DatasetLayer} from './dataset-layer';

export class Region extends MapTreeNode<Polygon> {

  constructor(name: string, polygon: Polygon, dataSetLayers?: DatasetLayer) {

    super(name, polygon);
    super.parentDatasetLayer = dataSetLayers;
  }
}
