<h2 mat-dialog-title>Save As</h2>
<mat-dialog-content>
  <form [formGroup]="filenameFormGroup">
    <mat-form-field class="w-100">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button
          [mat-dialog-close]="{name: filenameFormGroup.value.name, result: cancel}">Cancel
  </button>
  <button mat-raised-button class="mat-primary" type="submit"
          [mat-dialog-close]="{name: filenameFormGroup.value.name, result: save}">Save
  </button>
</mat-dialog-actions>
