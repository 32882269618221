import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'memoize'
})
export class MemoizePipe implements PipeTransform {

  // tslint:disable-next-line:ban-types
  transform(fn: Function, ...args: any[]): any {
    return fn(...args);
  }
}
