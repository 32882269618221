import {Component, Input} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {CsvFileService} from '../csv-file.service';
import {ParsedCsvFile} from '../../../shared/csv-file-preview-dialog/csv-file-preview-dialog.component';
import {DialogService} from '../../../dialog/dialog.service';

@Component({
  selector: 'app-csv-file-preview',
  templateUrl: './csv-file-preview.component.html',
  styleUrls: ['./csv-file-preview.component.scss']
})
export class CsvFilePreviewComponent {

  public sortedCsvRows: Array<any>;
  public csvFile: ParsedCsvFile;

  constructor(private dialogService: DialogService, private csvFileService: CsvFileService) {
  }

  @Input()
  public set parsedCsvFile(parsedCsvFile: ParsedCsvFile) {

    this.csvFile = parsedCsvFile;
    this.sortedCsvRows = this.csvFile.csvRows.slice();
  }

  private static sort<T>(isAsc: boolean, propertyKey: keyof T, sourceArray: Array<T>): Array<T> {

    return sourceArray.sort((a, b) => (a[propertyKey] < b[propertyKey] ? -1 : 1) * (isAsc ? 1 : -1));
  }

  public sort<T>(sort: Sort, sourceArray: Array<T> = []): Array<T> {

    if (!sort.active || sort.direction === '') {

      return sourceArray;

    } else {

      const isAsc = sort.direction === 'asc';
      const propertyKey = sort.active as keyof T;

      return CsvFilePreviewComponent.sort(isAsc, propertyKey, sourceArray);
    }
  }

  public onShowAllCsvRowsButtonClicked(parsedCsvFile: ParsedCsvFile): void {

    this.dialogService.openCsvFileDialog(parsedCsvFile).subscribe();
  }

  public onCloseButtonClicked(csvFile: ParsedCsvFile): void {

    this.csvFileService.removeParsedCsvFile(csvFile);
  }
}
