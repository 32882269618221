import * as L from 'leaflet';
import {CircleMarker, CircleMarkerOptions, LayerGroup, MarkerClusterGroupOptions, Polygon, Renderer} from 'leaflet';
import {colord} from 'colord';
import {freezeClusteringAtZoom} from '../../map/map.service';
import {MarkerClusterCoverageGroup} from '../../models/marker-cluster-coverage-group';
import 'leaflet.markercluster';

export class BirthLocationsLayersBuilder {

  private static CLUSTER_MARKER_OPTIONS: CircleMarkerOptions = {stroke: null, fill: null};

  private _birthLocations!: Array<CircleMarker>;
  private _birthLocationsRenderer?: Renderer;
  private _markerClusterOptions!: L.MarkerClusterGroupOptions;
  private _filterColor?: string;
  private _coverageDblClickListener?: (polygon: Polygon) => void;

  public setBirthLocations(birthLocations: Array<CircleMarker>): BirthLocationsLayersBuilder {

    this._birthLocations = birthLocations;

    return this;
  }

  public setBirthLocationsRenderer(renderer: Renderer): BirthLocationsLayersBuilder {

    this._birthLocationsRenderer = renderer;

    return this;
  }

  public setMarkerClusterOptions(markerClusterOptions: L.MarkerClusterGroupOptions): BirthLocationsLayersBuilder {

    this._markerClusterOptions = markerClusterOptions;

    return this;
  }

  public setDblClickListener(coverageDblClickListener: (polygon: Polygon) => void): BirthLocationsLayersBuilder {

    this._coverageDblClickListener = coverageDblClickListener;

    return this;
  }

  public setFilterColor(filterColor?: string): BirthLocationsLayersBuilder {

    this._filterColor = filterColor;

    return this;
  }

  public build(): {
    birthLocationsLayerGroup: LayerGroup<CircleMarker>;
    coverageLayerGroup: MarkerClusterCoverageGroup;
  } {

    const birthLocationsLayerGroup = this.createBirthLocationLayer(
      this._birthLocations, this._filterColor
    );
    const coverageLayerGroup = this.createMarkerClusterCoverageGroup(birthLocationsLayerGroup, this._markerClusterOptions);

    if (this._coverageDblClickListener) {

      coverageLayerGroup.addDblClickListener(this._coverageDblClickListener);
    }

    return {birthLocationsLayerGroup, coverageLayerGroup};
  }

  private createBirthLocationLayer(birthLocationMarkers: Array<CircleMarker>, filterColor?: string): LayerGroup<CircleMarker> {

    const isColorAllowed = (marker: CircleMarker): boolean => filterColor
      ? colord(marker.options.fillColor).brightness() <= colord(filterColor).brightness()
      : true;

    const circleMarkers = birthLocationMarkers
      .filter(isColorAllowed)
      .map(birthLocationMarker =>
        L.circleMarker(birthLocationMarker.getLatLng(), birthLocationMarker.options)
      );

    return new LayerGroup<CircleMarker>(circleMarkers);
  }

  private createMarkerClusterCoverageGroup(birthLocationMarkersGroup: LayerGroup<CircleMarker>,
                                           options: MarkerClusterGroupOptions): MarkerClusterCoverageGroup {

    const birthLocationsMarkers = birthLocationMarkersGroup.getLayers()
      .filter(layer => layer instanceof CircleMarker)
      .map(circleMarker => circleMarker as CircleMarker)
      .map(point => L.circleMarker(point.getLatLng(), BirthLocationsLayersBuilder.CLUSTER_MARKER_OPTIONS));

    return (new MarkerClusterCoverageGroup(options).addLayers(birthLocationsMarkers) as any).freezeAtZoom(freezeClusteringAtZoom);
  }
}
