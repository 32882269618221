import {Component, OnInit} from '@angular/core';
import {CsvFileService} from './csv-file.service';

@Component({
  selector: 'app-csv-files-sidebar',
  templateUrl: './csv-files-sidebar.component.html',
  styleUrls: ['./csv-files-sidebar.component.scss']
})
export class CsvFilesSidebarComponent implements OnInit {

  public readonly parsedCsvFiles$ = this.csvFileService.parsedCsvFiles$;

  constructor(private csvFileService: CsvFileService) {
  }

  ngOnInit(): void {
  }
}
