import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastrService: ToastrService) {
  }

  public showSuccess(message, title?: string) {

    this.toastrService.success(message, title);
  }

  public showError(message, title?: string) {

    this.toastrService.error(message, title);
  }

  public showInfo(message, title?: string) {

    this.toastrService.info(message, title);
  }

  public showWarning(message, title?: string) {

    this.toastrService.warning(message, title);
  }
}
