import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MapService} from '../../../map/map.service';
import CountiesJson from '../../../../assets/json/counties.json';
import * as L from 'leaflet';
import {FeatureGroup, Polygon} from 'leaflet';
import {Feature, FeatureCollection, LineString} from 'geojson';
import lineToPolygon from '@turf/line-to-polygon';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

const countyPolylineOptions: L.PolylineOptions = {fillColor: 'blue', fillOpacity: 0.2, color: 'blue', weight: 0.5};
const highlightedCountyPolylineOptions: L.PolylineOptions = {
  fillColor: 'red',
  fillOpacity: 0.4,
  color: 'red',
  weight: 1
};

@Component({
  selector: 'app-county-expansion-panel',
  templateUrl: './county-expansion-panel.component.html',
  styleUrls: ['./county-expansion-panel.component.scss']
})
export class CountyExpansionPanelComponent implements AfterViewInit {

  public readonly countiesMap = new Map<string, any>();
  public readonly countiesFeatureGroup: FeatureGroup = L.featureGroup();
  // @ts-ignore
  public readonly countiesLabelsFeatureGroup: FeatureGroup = L.LayerGroup.collision();
  public fruitCtrl = new UntypedFormControl('');
  public filteredCounties: Observable<string[]>;
  public selectedCounties: string[] = [];
  @ViewChild('countyInput') countyInput: ElementRef<HTMLInputElement>;
  public counties: boolean;
  private allCounties: string[];

  constructor(public mapService: MapService) {

    const generateUniqueName = (name: string, existingNames: Map<string, any>) => {

      const p = /(\w*)(.*\d)?/;

      while (existingNames.has(name)) {

        const m = p.exec(name);

        if (m) {

          name = `${m[1]} ${m[2] ? parseInt(m[2], 10) + 1 : 1}`;
        }
      }

      return name;
    };

    (CountiesJson as FeatureCollection).features
      .map(f => lineToPolygon(f as Feature<LineString>))
      .flatMap(geoJsonPolygon => L.geoJSON(geoJsonPolygon).getLayers())
      .map((polygon: Polygon) =>
        polygon
          .setStyle(countyPolylineOptions)
          .bindTooltip(polygon.feature.properties.name, {permanent: false, direction: 'center'})
      )
      .forEach((polygon: Polygon) => {

          const uniqueName = generateUniqueName(polygon.feature.properties.name, this.countiesMap);

          this.countiesMap.set(uniqueName, polygon);
        }
      );

    this.allCounties = Array.from(this.countiesMap.keys());

    this.filteredCounties = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allCounties.slice())),
    );
  }

  ngAfterViewInit(): void {

    this.countiesMap.forEach((polygon: Polygon) => this.addCounty(polygon));
  }

  public addCounty(countyPolygon: L.Polygon): void {

    this.countiesFeatureGroup.addLayer(countyPolygon);

    const labelMarker = MapService.labelMarker(countyPolygon.getBounds().getCenter(), countyPolygon.feature.properties.name);
    this.countiesLabelsFeatureGroup.addLayer(labelMarker);
  }

  public remove(countyName: string): void {

    const countyPolygon = this.countiesMap.get(countyName);

    countyPolygon.setStyle(countyPolylineOptions).bringToFront();

    const index = this.selectedCounties.indexOf(countyName);

    if (index >= 0) {
      this.selectedCounties.splice(index, 1);
    }
  }

  public selected(event: MatAutocompleteSelectedEvent): void {

    const countyPolygon = this.countiesMap.get(event.option.viewValue);

    this.selectedCounties.push(event.option.viewValue);
    countyPolygon.setStyle(highlightedCountyPolylineOptions).bringToFront();
    this.countyInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  public onCountyChipClicked(county: string): void {

    const countyPolygon: L.Polygon = this.countiesMap.get(county);
    countyPolygon.bringToFront();
  }

  public onBringToFrontButtonClicked(): void {

    this.countiesMap.forEach((value: Polygon) => value.bringToFront());
  }

  public onBringToBackButtonClicked(): void {

    this.countiesMap.forEach((value: Polygon) => value.bringToBack());
  }

  public onCountyChipDblClicked(county: string) {

    const countyPolygon: L.Polygon = this.countiesMap.get(county);
    this.mapService.zoomToCounty(countyPolygon);
  }

  public onClusteringCheckboxChanged(event: Event): void {

    const checked = (<HTMLInputElement>event.target).checked;

    if (checked) {

      this.enableCounties();

    } else {

      this.disableCounties();
    }
  }

  public enableCounties(): void {

    this.counties = true;

    if (!this.mapService.hasLayer(this.countiesFeatureGroup)) {

      this.mapService.addLayer(this.countiesFeatureGroup);
    }

    if (!this.mapService.hasLayer(this.countiesLabelsFeatureGroup)) {

      this.mapService.addLayer(this.countiesLabelsFeatureGroup);
    }
  }

  public disableCounties(): void {

    if (this.mapService.hasLayer(this.countiesLabelsFeatureGroup)) {

      this.mapService.removeLayer(this.countiesLabelsFeatureGroup);
    }

    if (this.mapService.hasLayer(this.countiesFeatureGroup)) {

      this.mapService.removeLayer(this.countiesFeatureGroup);
    }

    this.counties = false;
  }

  private _filter(value: string): string[] {

    const filterValue = value.toLowerCase();

    return this.allCounties.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }
}
