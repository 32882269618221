import {CircleMarker, LayerGroup} from 'leaflet';
import {MapTreeNode} from './map-tree-node';
import {MarkerClusterCoverageGroup} from './marker-cluster-coverage-group';
import {Region} from './region';
import {includes, pull} from 'lodash-es';
import {Image} from './image';

export class DatasetLayer extends MapTreeNode<LayerGroup<CircleMarker>> {

  birthLocations: Array<CircleMarker>;
  coveragesLayerGroup: MarkerClusterCoverageGroup;
  clustering = false;
  childNodes = new Array<MapTreeNode<any>>();

  constructor(name: string,
              birthLocations: Array<CircleMarker>,
              clustering: boolean) {

    super(name, new LayerGroup<CircleMarker>());
    this.birthLocations = birthLocations;
    this.clustering = clustering;
  }

  public addMapTreeNode(mapEntity: MapTreeNode<any>): DatasetLayer {

    this.childNodes.push(mapEntity);

    return mapEntity.parentDatasetLayer = this;
  }

  public removeMapTreeNode(mapTreeNode: MapTreeNode<any>): DatasetLayer {

    if (!includes(this.childNodes, mapTreeNode)) {

      throw new Error('Tree node was not found');
    }

    pull(this.childNodes, mapTreeNode);
    mapTreeNode.parentDatasetLayer = null;

    return this;
  }

  public addClusteringTo(layerGroup: LayerGroup): DatasetLayer {

    if (!this.clustering) {

      this.clustering = layerGroup.addLayer(this.coveragesLayerGroup)
        .hasLayer(this.coveragesLayerGroup);
    }

    return this;
  }

  public removeClusteringFrom(layerGroup: LayerGroup): DatasetLayer {

    if (this.clustering) {

      this.clustering = layerGroup.removeLayer(this.coveragesLayerGroup)
        .hasLayer(this.coveragesLayerGroup);
    }

    return this;
  }

  public buildCoverages(): DatasetLayer {

    this.coveragesLayerGroup.fireAnimationEndEvent();

    return this;
  }

  public updateLayers(layers: {
    birthLocationsLayerGroup: LayerGroup<CircleMarker>;
    coverageLayerGroup: MarkerClusterCoverageGroup;
  }): DatasetLayer {

    this.layer = layers.birthLocationsLayerGroup;
    this.coveragesLayerGroup = layers.coverageLayerGroup;

    return this;
  }

  public getRegions(): Array<Region> {

    return this.childNodes
      .filter(entity => entity instanceof Region)
      .map(regionEntity => regionEntity as Region);
  }

  public getImages(): Array<Image> {

    return this.childNodes
      .filter(entity => entity instanceof Image)
      .map(imageEntity => imageEntity as Image);
  }
}
