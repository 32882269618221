import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {isArray} from 'lodash-es';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

export interface ParsedCsvFile {
  name: string;
  headers?: Array<any>;
  csvRows: Array<object | Array<any>>;
}

@Component({
  selector: 'app-csv-file-preview-dialog',
  templateUrl: './csv-file-preview-dialog.component.html',
  styleUrls: ['./csv-file-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsvFilePreviewDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public displayedColumnKeys = new Array<string>();
  public displayedColumnValues = new Array<string>();
  public readonly dataSource = new MatTableDataSource();
  public readonly tablePropertiesFormGroup: FormGroup<{
    searchText: FormControl<string>
  }> = this.formBuilder.nonNullable.group({
    searchText: ['']
  });
  private searchTextFilterSubscription!: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ParsedCsvFile,
              private formBuilder: FormBuilder) {
  }

  @Input()
  public set parsedCsvFile(parsedCsvFile: ParsedCsvFile) {

    if (parsedCsvFile.headers) {

      this.displayedColumns = parsedCsvFile.headers;

    } else {

      const firstSourceObject = parsedCsvFile.csvRows.find(Boolean);

      const headerNameValues = isArray(firstSourceObject)
        ? Object.keys(firstSourceObject).map(key => [key, 'Column ' + key])
        : Object.keys(firstSourceObject).map(key => [key, key]);
      this.displayedColumns = Object.fromEntries(headerNameValues);
    }

    this.dataSource.data = parsedCsvFile.csvRows;
  }

  public set displayedColumns(headerObject: object) {

    this.displayedColumnKeys = Object.keys(headerObject);
    this.displayedColumnValues = Object.values(headerObject);
  }

  public ngOnInit(): void {

    this.searchTextFilterSubscription = this.tablePropertiesFormGroup.controls.searchText.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchText => this.dataSource.filter = (searchText || '').toLowerCase());
  }

  public ngAfterViewInit(): void {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    setTimeout(() => this.parsedCsvFile = this.data);
  }

  public ngOnDestroy(): void {

    this.searchTextFilterSubscription.unsubscribe();
  }
}
