import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private static readonly BASE_LAYER_KEY = 'baseLayer';

  constructor() {
  }

  public setBaseLayer(name: string): void {

    this.set(LocalStorageService.BASE_LAYER_KEY, name);
  }

  public getBaseLayer(): string | null {

    return this.get(LocalStorageService.BASE_LAYER_KEY);
  }

  private set(baseLayer: string, name: string): void {

    localStorage.setItem(baseLayer, name);
  }

  private get(name: string): string | null {

    return localStorage.getItem(name);
  }
}
