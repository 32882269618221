import {MapTreeNode} from './map-tree-node';
import {DatasetLayer} from './dataset-layer';
import {ImageOverlay, LayerGroup} from 'leaflet';
import {DistortableImageOverlayBuilder} from '../shared/builder/distortable-image-overlay-builder';

export class Image extends MapTreeNode<ImageOverlay> {

  constructor(name: string, imageOverlay: ImageOverlay, dataSetLayers?: DatasetLayer) {

    super(name, imageOverlay);
    super.parentDatasetLayer = dataSetLayers;
  }

  public removeFrom(layerGroup: LayerGroup): this {

    const imageOverlayCopy = new DistortableImageOverlayBuilder()
      .from(this.layer)
      .setSelectListener(e => this.layerSelectListeners.forEach(listener => listener(e)))
      .setOneTimeAddListener(e => this.oneTimeLayerAddListeners.forEach(listener => listener(e)))
      .setOneTimeLoadListener(e => this.oneTimeLayerLoadListeners.forEach(listener => listener(e)))
      .build();

    const imageOverlayMapTreeNode = super.removeFrom(layerGroup);

    //TODO: Workaround for known bug. Recreate image every time because it disappears after removing from the map
    this.layer = imageOverlayCopy;

    return imageOverlayMapTreeNode;
  }
}
