import {isArrayLike} from 'lodash-es';

export class FileUtils {

  public static getFiles(event: Event): Array<File> {

    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    return Array.from(files);
  }

  public static parseJsonArray<T>(json: string): Array<T> {

    const obj = JSON.parse(json);

    if (!isArrayLike(obj)) {

      throw new Error('Can not process unrecognized JSON structure');
    }

    return obj as Array<T>;
  }
}
