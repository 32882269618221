<div class="form-check form-check-inline">
  <input [checked]="counties" (change)="onClusteringCheckboxChanged($event)"
         class="form-check-input" type="checkbox" id="counties">
  <label class="form-check-label" for="counties">Counties</label>
</div>

<mat-form-field class="w-100" appearance="outline">
  <mat-label>Highlighted Counties</mat-label>
  <mat-chip-grid #chipList aria-label="Fruit selection">
    <mat-chip-row *ngFor="let county of selectedCounties" (click)="onCountyChipClicked(county)"
                  (dblclick)="onCountyChipDblClicked(county)"
                  (removed)="remove(county)">
      {{county}}
      <mat-icon matChipTrailingIcon (click)="onCountyChipDblClicked(county)" class="px-1">zoom_in</mat-icon>
      <mat-icon matChipRemove class="px-1">cancel</mat-icon>
    </mat-chip-row>
    <input
      placeholder="New County..."
      #countyInput
      [formControl]="fruitCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList">
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let county of filteredCounties | async" [value]="county">
      {{county}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<div class="d-flex gap-2">
  <button class="w-100 btn btn-sm btn-primary" (click)="onBringToFrontButtonClicked()">
    Bring To Front
  </button>
  <button class="w-100 btn btn-sm btn-secondary" (click)="onBringToBackButtonClicked()">
    Bring To Back
  </button>
</div>
