import {Injectable} from '@angular/core';
import {MapService} from '../../../map/map.service';
import * as omnivore from '@mapbox/leaflet-omnivore';
import * as L from 'leaflet';
import {FeatureGroup, LatLng} from 'leaflet';
import {SelectionModel} from '@angular/cdk/collections';
import {filter, map, mergeAll, pairwise, startWith} from 'rxjs/operators';

export class ImportedGeospatialData {

    constructor(public filename: string,
                public geoJSON: L.GeoJSON,
                public propertiesKeys: Set<string> = new Set<string>(),
                // @ts-ignore
                public geoJSONPropertiesLabels: FeatureGroup = L.LayerGroup.collision()) {
    }

    public static findProperties(geoJsonProperties: {
        [name: string]: any
    } | null, propertiesKeysToInclude: Array<string> = []): [string, any][] {

        return Object.entries(geoJsonProperties)
            .filter(([key]) => propertiesKeysToInclude.includes(key))
    }
}

@Injectable({
    providedIn: 'root'
})
export class ImportedGeospatialDataService {

    public readonly featureGroupSelectionModel = new SelectionModel<ImportedGeospatialData>(true);
    public readonly allImportedGeospatialDataClosed$ = this.featureGroupSelectionModel.changed.pipe(
        map(selectionChange =>
            selectionChange ? selectionChange.source.isEmpty() : this.featureGroupSelectionModel.isEmpty()
        ),
        startWith(this.featureGroupSelectionModel.isEmpty()),
        pairwise(),
        filter(([previous, current]) => !previous && current)
    )
    public readonly firstGeospatialDataImported$ = this.featureGroupSelectionModel.changed.pipe(
        map(selectionChange =>
            selectionChange ? selectionChange.source.hasValue() : this.featureGroupSelectionModel.hasValue()
        ),
        startWith(this.featureGroupSelectionModel.hasValue()),
        pairwise(),
        filter(([previous, current]) => !previous && current)
    )
    private readonly mapAddFeatureGroupSubscription = this.featureGroupSelectionModel.changed.pipe(
        map(selectionChange => selectionChange.added),
        mergeAll()
    ).subscribe(group =>
        this.mapService
            .addLayer(group.geoJSON)
            .addLayer(group.geoJSONPropertiesLabels)
    );
    private readonly mapRemoveFeatureGroupSubscription = this.featureGroupSelectionModel.changed.pipe(
        map(selectionChange => selectionChange.removed),
        mergeAll()
    ).subscribe(geospatialData =>
        this.mapService
            .removeLayer(geospatialData.geoJSONPropertiesLabels)
            .removeLayer(geospatialData.geoJSON)
    );

    constructor(private mapService: MapService) {
    }

    private static parseGeospatialData(fileName: string, str: string): L.GeoJSON {

        const fileExtension = fileName.split('.').pop();

        switch (fileExtension) {

            case 'csv' : {

                return omnivore.csv.parse(str);
            }
            case 'gpx' : {

                return omnivore.gpx.parse(str);
            }
            case 'kml' : {

                return omnivore.kml.parse(str);
            }
            case 'wkt' : {

                return omnivore.wkt.parse(str);
            }
            case 'topojson' : {

                return omnivore.topojson.parse(str);
            }
            case 'geojson' : {

                return ImportedGeospatialDataService.parseGeojson(str);
            }
            default:

                throw new Error('Unknown file extension');
        }
    }

    private static parseGeojson(geojsonObjStr: string): L.GeoJSON {

        return L.geoJSON(
            JSON.parse(geojsonObjStr),
            {
                onEachFeature: function (feature, layer) {
                    const content = Object.entries(feature.properties)
                        .map(([key, value]) => key + ': ' + value)
                        .join('</br>');
                    layer.bindPopup(content);
                }
            });
    }

    private static getPropertiesKeys(geoJSON: L.GeoJSON): Set<string> {

        const propertiesKeys = geoJSON.getLayers()
            .filter(layer => layer instanceof L.Polygon)
            .flatMap((polygon: L.Polygon) => Object.keys(polygon.feature.properties));

        return new Set<string>(propertiesKeys);
    }

    public add(filename: string, content: string): void {

        const featureGroup = ImportedGeospatialDataService.parseGeospatialData(filename, content);
        const propertiesKeys = ImportedGeospatialDataService.getPropertiesKeys(featureGroup);
        const importedFeatureGroup = new ImportedGeospatialData(filename, featureGroup, propertiesKeys);

        this.featureGroupSelectionModel.select(importedFeatureGroup);
    }

    public remove(featureGroup: ImportedGeospatialData): void {

        this.featureGroupSelectionModel.deselect(featureGroup);
    }

    public displayProperties(featureGroup: ImportedGeospatialData, propertiesKeys: Array<string>): void {

        featureGroup.geoJSONPropertiesLabels.clearLayers();

        featureGroup.geoJSON.getLayers()
            .filter(layer => layer instanceof L.Polygon)
            .map((layer: L.Polygon): [LatLng, [string, any][]] => [
                layer.getBounds().getCenter(),
                ImportedGeospatialData.findProperties(layer.feature.properties, propertiesKeys)
            ])
            .filter(([, labelProperties]) => labelProperties.length)
            .map(([latLng, labelProperties]) =>
                MapService.labelMarker(latLng, ...labelProperties.map(([key, value]) => `${key}: ${value}`))
            ).forEach(marker => featureGroup.geoJSONPropertiesLabels.addLayer(marker));
    }
}
