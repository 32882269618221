import {Papa} from 'ngx-papaparse';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CsvParser {

  constructor(private papa: Papa) {
  }

  public parse(surnamesFileText: string | ArrayBuffer): any {

    return this.papa.parse(surnamesFileText.toString(), {
      dynamicTyping: true,
      skipEmptyLines: true,
      delimitersToGuess: [',', '\t']
    }).data;
  }
}
