import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'pixelsMiles'
})
export class PixelsMilesPipe implements PipeTransform {

  transform(pixels: number, milesPerPixel: number): number {

    return Math.round(pixels * milesPerPixel);
  }
}
