import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this.loadingSubject.asObservable();
  private totalRequests = 0;

  public set load(load: boolean) {

    this.totalRequests += load ? 1 : -1;

    this.loadingSubject.next(true);

    if (this.totalRequests <= 0) {

      this.loadingSubject.next(false);
    }
  }
}
