import * as L from 'leaflet';
import {Polygon} from 'leaflet';
import polygonSmooth from '@turf/polygon-smooth';
import convex from '@turf/convex';
import union from '@turf/union';
import {MapTreeNode} from '../../models/map-tree-node';
import {flattenDeep, isNil, uniq} from 'lodash-es';

export default class MapUtils {

  private static readonly EARTH_CIRCUMFERENCE = 40075016.686;

  public static combineRegionsByUnion(polygon1: L.Polygon, polygon2: L.Polygon): L.Polygon {

    const featureCollection = union(polygon1.toGeoJSON(), polygon2.toGeoJSON());

    const polygon = (L.geoJSON(featureCollection).getLayers() as Array<L.Polygon>).find(Boolean);

    polygon1.setLatLngs(polygon.getLatLngs());
    // @ts-ignore
    polygon1.editing = new L.Edit.Poly(polygon1);

    return polygon1;
  }

  public static combineRegionsByConvex(polygon1: Polygon, polygon2: Polygon): Polygon {

    const features = [polygon1.toGeoJSON(), polygon2.toGeoJSON()];
    // @ts-ignore
    const polygonsConvexFeature = convex({features, type: 'FeatureCollection'});

    const polygon = (L.geoJSON(polygonsConvexFeature).getLayers() as Array<Polygon>).find(Boolean);

    polygon1.setLatLngs(polygon.getLatLngs());
    // @ts-ignore
    polygon1.editing = new L.Edit.Poly(polygon1);

    return polygon1;
  }

  public static combineRegionsByCoordinates(polygon1: Polygon, polygon2: Polygon): Polygon {

    const latLngs1 = flattenDeep(polygon1.getLatLngs());
    const latLngs2 = flattenDeep(polygon2.getLatLngs());

    const combinedPolygon = L.polygon(latLngs1.concat(latLngs2));
    polygon1.setLatLngs(combinedPolygon.getLatLngs());
    // @ts-ignore
    polygon1.editing = new L.Edit.Poly(polygon1);

    return polygon1;
  }

  public static smoothPolygons(...polygons: Polygon[]): Array<Polygon> {

    return polygons.map((polygon: Polygon) => {

      const smoothedPolygon = MapUtils.smooth(polygon);
      polygon.setLatLngs(smoothedPolygon.getLatLngs());
      // @ts-ignore
      polygon.editing = new L.Edit.Poly(polygon);

      return polygon;
    });
  }

  public static smooth(polygon: Polygon): Polygon {

    const polygonFeature = polygon.toGeoJSON();
    const featureCollection = polygonSmooth(polygonFeature, {iterations: 1});
    const polygonLayers = L.geoJSON(featureCollection).getLayers() as Array<L.Polygon>;

    return polygonLayers.find(Boolean);
  }

  public static clonePolygon(polygon: Polygon): Polygon {

    const latLngs = flattenDeep(polygon.getLatLngs());

    const clonedLatLngs = L.LatLngUtil.cloneLatLngs(latLngs);

    return L.polygon(clonedLatLngs, polygon.options);
  }

  public static calculateMilesPerPixel(leafletMap: L.Map, freezeClusteringAtZoom: number): number {

    const metersPerPixel = MapUtils.EARTH_CIRCUMFERENCE *
      Math.abs(Math.cos(leafletMap.getCenter().lat * Math.PI / 180)) / Math.pow(2, freezeClusteringAtZoom + 8);

    return metersPerPixel / 1609.344;
  }

  public static getParentNodes(treeNodes: Array<MapTreeNode<any>>): Array<MapTreeNode<any>> {

    const parent = (node: MapTreeNode<any>): MapTreeNode<any> => isNil(node.parentDatasetLayer)
      ? node
      : parent(node.parentDatasetLayer);

    const parents = treeNodes.map(node => parent(node));

    return uniq(parents);
  }

  public static mapToFeaturedLayer(polygon: L.Polygon) {

    const layer = MapUtils.clonePolygon(polygon);

    const geoJson = layer.toGeoJSON();
    geoJson.properties = geoJson.properties || {}

    layer.feature = geoJson;

    return layer;
  }
}
