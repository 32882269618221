import {DatasetLayer} from './dataset-layer';
import {Layer, LayerGroup, LeafletEvent, Map} from 'leaflet';

export abstract class MapTreeNode<T extends Layer> {

  public parentDatasetLayer?: DatasetLayer;
  public displaying = false;

  protected readonly layerSelectListeners = new Array<(event: LeafletEvent) => void>();
  protected readonly oneTimeLayerAddListeners = new Array<(event: LeafletEvent) => void>();
  protected readonly oneTimeLayerLoadListeners = new Array<(event: LeafletEvent) => void>();

  protected constructor(public name: string, public layer: T) {

    layer.addEventListener('select', e =>
      this.layerSelectListeners.forEach(listener => listener(e))
    );
    layer.addOneTimeEventListener('add', e => {
      this.oneTimeLayerAddListeners.forEach(listener => listener(e))
    });
    layer.addOneTimeEventListener('load', e =>
      this.oneTimeLayerLoadListeners.forEach(listener => listener(e))
    );
  }

  public removeFrom(layerGroup: LayerGroup | Map): this {

    if (this.displaying) {

      this.displaying = layerGroup.removeLayer(this.layer)
        .hasLayer(this.layer);
    }

    return this;
  }

  public addTo(layerGroup: LayerGroup | Map): this {

    if (!this.displaying) {

      this.displaying = layerGroup.addLayer(this.layer)
        .hasLayer(this.layer);
    }

    return this;
  }

  public updateWith(newMapEntity: MapTreeNode<T>): this {

    this.name = newMapEntity.name;

    return this;
  }

  public addLayerSelectListener(func: (event: LeafletEvent) => void): MapTreeNode<any> {

    this.layerSelectListeners.push(func);

    return this;
  }

  public addOneTimeLayerAddListener(func: (event: LeafletEvent) => void): MapTreeNode<any> {

    this.oneTimeLayerAddListeners.push(func);

    return this;
  }

  public addOneTimeLayerLoadListener(func: (event: LeafletEvent) => void): MapTreeNode<any> {

    this.oneTimeLayerLoadListeners.push(func);

    return this;
  }
}
