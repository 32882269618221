import {Component} from '@angular/core';
import {RegionExportDto} from '../models/export/region-export-dto';
import * as L from 'leaflet';
import {DatasetLayer} from '../models/dataset-layer';
import {CommunityBirthLocationsParser} from '../shared/community-birth-locations-parser';
import {MapService} from '../map/map.service';
import {map, startWith} from 'rxjs/operators';
import {NotificationService} from '../notification/notification.service';
import {CsvFileService} from '../sidebar/csv-files-sidebar/csv-file.service';
import {LoadingService} from '../shared/loading.service';
import {
  ImportedGeospatialDataService
} from '../sidebar/layers-sidebar/geospatial-data-expansion-panel/imported-geospatial-data.service';
import {isEmpty} from 'lodash-es';
import {Image} from '../models/image';
import {DistortableImageOverlayBuilder} from '../shared/builder/distortable-image-overlay-builder';
import {Region} from '../models/region';
import {FileUtils} from '../shared/utils/file-utils';
import {DatasetDtoToDatasetMapperFactory} from '../shared/mapper/dataset-dto-to-dataset-mapper-factory';
import {ExportToJsonService} from '../export/export-to-json.service';
import {SaveAsDialogResult} from '../dialog/name-edit-dialog/name-edit-dialog.component';
import {saveAs} from 'file-saver-es';
import {DialogService} from '../dialog/dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public static readonly OPTIONS: BlobPropertyBag = {type: 'text/plain;charset=utf-8'}

  public datasetsIsNotOpened$ = this.mapService.dataSetLayers$.pipe(
    startWith([]),
    map(datasets => isEmpty(datasets))
  );

  constructor(private dialogService: DialogService,
              public mapService: MapService,
              private csvFileService: CsvFileService,
              private geospatialDataService: ImportedGeospatialDataService,
              private notifyService: NotificationService,
              private loadingService: LoadingService,
              private exportService: ExportToJsonService) {
  }

  public onFileChange(event: Event): void {

    FileUtils.getFiles(event).forEach(file => {

      const reader = new FileReader();
      reader.onloadstart = () => this.loadingService.load = true;
      reader.onloadend = () => this.loadingService.load = false;
      reader.onload = (e) => this.handleFileLoaded(e, file);
      reader.readAsText(file);
    });
  }

  public onDatasetFileChange(event: Event): void {

    FileUtils.getFiles(event).forEach(file => {

      const reader = new FileReader();
      reader.onloadstart = () => this.loadingService.load = true;
      reader.onloadend = () => this.loadingService.load = false;
      reader.onload = (e) => this.handleDatasetReaderLoaded(e);
      reader.readAsText(file);
    });
  }

  public onRegionFileChange(event: Event): void {

    FileUtils.getFiles(event).forEach(file => {

      const reader = new FileReader();
      reader.onloadstart = () => this.loadingService.load = true;
      reader.onloadend = () => this.loadingService.load = false;
      reader.onload = (e) => this.handleRegionReaderLoaded(e);
      reader.readAsText(file);
    });
  }

  public onGeospatialDataFileChange(event: Event): void {

    FileUtils.getFiles(event).forEach(file => {

      const reader = new FileReader();
      reader.onloadstart = () => this.loadingService.load = true;
      reader.onloadend = () => this.loadingService.load = false;
      reader.onload = (e) => this.handleGeospatialDataReaderLoaded(e, file);
      reader.readAsText(file);
    });
  }


  public onImageFileChange(event: Event): void {

    FileUtils.getFiles(event).forEach(file => {

      const reader = new FileReader();
      reader.onloadstart = () => this.loadingService.load = true;
      reader.onloadend = () => this.loadingService.load = false;
      reader.onload = (e) => this.handleImageDataReaderLoaded(e, file);
      reader.readAsDataURL(file);
    });
  }

  public onExportRegionsButtonClicked(): void {

    const regionsFileBlob = this.exportService.exportRegions();

    const filename = this.defaultFilename();

    this.saveFile(filename, new Blob(regionsFileBlob, HeaderComponent.OPTIONS));
  }

  public onExportDatasetButtonClicked(): void {

    const datasetFileBlob = this.exportService.exportDataset();

    const filename = this.defaultFilename();

    this.saveFile(filename + '_dataset', new Blob(datasetFileBlob, HeaderComponent.OPTIONS));
  }

  public onExportGeoJsonButtonClicked(): void {

    const geoJsonFileBlob = this.exportService.exportToGeoJson();

    const filename = this.defaultFilename();

    this.saveFile(filename, new Blob(geoJsonFileBlob, HeaderComponent.OPTIONS));
  }

  public onButtonCloseDatasetClicked(): void {

    this.mapService.disableClustering();
    this.mapService.deleteDatasetLayers();
  }

  private saveFile(filename: string, fileBlob: Blob): void {

    this.dialogService.openNameEditDialog(filename).subscribe((dialogResult: SaveAsDialogResult) => {

      saveAs(fileBlob, dialogResult.name + '.json');
      this.notifyService.showSuccess(`${filename} was saved successfully`);
    });
  }

  private defaultFilename(): string {

    const datasetLayers = this.mapService.getDatasetLayers();
    return datasetLayers.length ? datasetLayers[datasetLayers.length - 1].name : '';
  }

  private handleFileLoaded(event: ProgressEvent<FileReader>, file: File): void {

    const fileExtension = file.name.split('.').pop();

    switch (fileExtension) {

      case 'html' : {

        new CommunityBirthLocationsParser()
          .parse(event.target.result)
          .forEach((circleMarkers, key) => this.mapService.addBirthLocations(key, circleMarkers));

        break;
      }
      case 'txt' : {

        this.csvFileService.addFile({
          name: file.name,
          content: event.target.result
        });

        break;
      }
    }
  }

  private handleRegionReaderLoaded(e: ProgressEvent<FileReader>): void {

    FileUtils.parseJsonArray<RegionExportDto>(<string>e.target.result).forEach((region: RegionExportDto) => {

      const dataset = this.mapService.findOrCreateDataset(region.id);

      const polygon: L.Polygon = L.geoJSON(region.polygon).getLayers()
        .filter(layer => layer instanceof L.Polygon).map(p => p as L.Polygon)
        .find(Boolean);
      this.mapService.addRegionLayer(dataset, region.name, polygon);
    });
  }

  private handleDatasetReaderLoaded(e: ProgressEvent<FileReader>): void {

    FileUtils.parseJsonArray<any>(<string>e.target.result)
      .map(dataset => new DatasetDtoToDatasetMapperFactory().for(dataset).map(dataset))
      .forEach((loadedDataset: DatasetLayer) => {

        const dataset = this.mapService.addBirthLocations(loadedDataset.name, loadedDataset.birthLocations);
        loadedDataset.childNodes.forEach(node => {
            switch (node.constructor) {
              case Region: {
                this.mapService.addRegionLayer(dataset, node.name, node.layer);
                break;
              }
              case Image: {
                this.mapService.addImageLayer(dataset, node.name, node.layer);
                break;
              }
            }
          }
        );
      });
  }

  private handleGeospatialDataReaderLoaded(event: ProgressEvent<FileReader>, file: File): void {

    try {

      this.geospatialDataService.add(file.name, <string>event.target.result);

    } catch (err) {

      console.error(err);
      this.notifyService.showError(err.message, 'Can not parse geometry');
    }
  }

  private handleImageDataReaderLoaded(event: ProgressEvent<FileReader>, file: File): void {

    try {

      const imageOverlay = new DistortableImageOverlayBuilder()
        .setUrl(<string>event.target.result)
        .build();

      this.mapService.addImage(new Image(
        file.name,
        imageOverlay
      ));

    } catch (err) {

      console.error(err);
      this.notifyService.showError(err.message, 'Can not parse geometry');
    }
  }
}
