import {Component, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface SaveAsDialogData {
  name: string;
}

export enum Result {

  SAVE,
  CANCEL
}

export interface SaveAsDialogResult {
  name: string;
  result: Result;
}

@Component({
  selector: 'app-naming-dialog',
  templateUrl: './name-edit-dialog.component.html',
  styleUrls: ['./name-edit-dialog.component.scss']
})
export class NameEditDialogComponent {

  public readonly save = Result.SAVE;
  public readonly cancel = Result.CANCEL;

  public readonly filenameFormGroup: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SaveAsDialogData,
              private formBuilder: UntypedFormBuilder) {

    this.filenameFormGroup = this.formBuilder.group({
      name: [this.data.name, Validators.required]
    });
  }
}
