import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ImportedGeospatialData} from '../imported-geospatial-data.service';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatChipListbox, MatChipOption} from '@angular/material/chips';

@Component({
  selector: 'app-imported-feature-group-card',
  templateUrl: './imported-feature-group-card.component.html',
  styleUrls: ['./imported-feature-group-card.component.scss']
})
export class ImportedFeatureGroupCardComponent implements AfterViewInit, OnDestroy {

  @Output() public readonly removeImportedFeatureGroup = new EventEmitter<ImportedGeospatialData>();
  @Output() public readonly propertiesKeysSelectionChange = new EventEmitter<[ImportedGeospatialData, Array<string>]>();
  @ViewChild(MatChipListbox) matChipList!: MatChipListbox;
  private chipSelectionChangesSubscription!: Subscription;

  constructor() {
  }

  public _featureGroup: ImportedGeospatialData;

  @Input()
  public set featureGroup(featureGroup: ImportedGeospatialData) {

    this._featureGroup = featureGroup;
  }

  ngAfterViewInit(): void {

    this.chipSelectionChangesSubscription = this.matChipList.chipSelectionChanges.pipe(
      map(() => this.matChipList.selected),
      map((selectedChips: Array<MatChipOption>) => selectedChips.map(chip => chip.value))
    ).subscribe((selectedPropertiesKeys: Array<string>) => this.propertiesKeysSelectionChange.emit([this._featureGroup, selectedPropertiesKeys]));
  }

  ngOnDestroy(): void {

    this.chipSelectionChangesSubscription.unsubscribe();
  }
}
