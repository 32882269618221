<div class="h-100 d-flex flex-column flex-nowrap border-end">

  <div [hidden]="doNotShowSpinner$ | async" class="position-absolute loading-shade">
    <mat-spinner class="m-auto"></mat-spinner>
  </div>

  <div class="h-240px d-flex flex-column flex-nowrap border-bottom mt-1">

    <ul class="nav nav-tabs" role="tablist">
      <li *ngFor="let tab of openedTabs" class="nav-item">
        <button (click)="onTabClick(tab)" [class.active]="activeTab === tab"
                type="button" class="nav-link py-0" role="tab">
          <mat-icon class="align-middle">{{ tabIcons[tab] }}</mat-icon>
          <span class="pe-1">{{ tab }}</span>
        </button>
      </li>
    </ul>

    <div class="flex-grow-1 tab-content overflow-hidden">
      <div class="h-100 tab-pane p-3 fade overflow-auto"
           [ngClass]="{'show active' : activeTab === tab.CLUSTERING}"
           role="tabpanel">

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="clustering"
                 (change)="onClusteringCheckboxChanged($event)"
                 [checked]="mapService.clusteringEnabled$ | async">
          <label class="form-check-label" for="clustering">Enable</label>
        </div>

        <form [formGroup]="clusteringFormGroup">
          <fieldset>
            <div class="row">
              <label class="col-auto col-form-label" for="radius">Radius (miles):</label>
              <div class="col">
                <input type="number" class="form-control-plaintext" readonly
                       [value]="clusteringFormGroup.value.radius | pixelsMiles:this.mapService.milesPerPixel">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input formControlName="radius" class="form-range" type="range" id="radius"
                       name="radius"
                       min="0" [max]="maxClusterRadius">
              </div>
            </div>
            <div class="row">
              <label class="col-auto col-form-label" for="filterColorIndex">Log(OR):</label>
              <div class="col">
                <div class="h-100 w-100"
                     [style.background]="colors[clusteringFormGroup.value.filterColorIndex]">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <datalist id="tickmarks">
                  <option class="w-100 bg-light" [value]="-1"></option>
                  <option class="w-100" *ngFor="let color of colors; index as idx" [value]="idx"
                          [label]="logOrTitleForColor | memoize:color:logOrForColor"
                          [style.background]="color">
                  </option>
                </datalist>
                <input formControlName="filterColorIndex" list="tickmarks" class="form-range"
                       type="range"
                       id="filterColorIndex"
                       name="filterColorIndex"
                       min="-1" [max]="colors.length - 1">
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="h-100 tab-pane p-3 fade overflow-auto"
           [ngClass]="{'show active' : activeTab === tab.GEOSPATIAL_DATA}"
           role="tabpanel">
        <app-geospatial-data-expansion-panel></app-geospatial-data-expansion-panel>
      </div>
      <div class="h-100 tab-pane p-3 fade overflow-auto"
           [ngClass]="{'show active' : activeTab === tab.COUNTIES}"
           role="tabpanel">
        <app-county-expansion-panel></app-county-expansion-panel>
      </div>
    </div>

  </div>

  <main class="flex-grow-1 overflow-hidden vstack mt-1">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <button [class.active]="true" type="button" class="nav-link py-0" role="tab">
          Dataset
        </button>
      </li>
    </ul>

    <div class="flex-grow-1 tab-content overflow-hidden vstack">

      <div class="d-flex justify-content-start border-bottom">
        <ng-template [ngIf]="treeControl.expansionModel.selected.length"
                     [ngIfElse]="expandAll">
          <button mat-button (click)="treeControl.collapseAll()" title="Collapse All Layers">
            <mat-icon>expand_more</mat-icon>
            Collapse All
          </button>
        </ng-template>
        <ng-template #expandAll>
          <button mat-button (click)="treeControl.expandAll()" title="Expand All Layers">
            <mat-icon>chevron_right</mat-icon>
            Expand All
          </button>
        </ng-template>

        <ng-template [ngIf]="mapService.drawnItems.getLayers().length" [ngIfElse]="showRegions">
          <button mat-button (click)="onHideAllRegionsButtonClicked()" title="Hide All Regions">
            <mat-icon>visibility</mat-icon>
            Hide Regions
          </button>
        </ng-template>
        <ng-template #showRegions>
          <button mat-button (click)="onDisplayAllRegionsButtonClicked()" title="Show All Regions">
            <mat-icon>visibility_off</mat-icon>
            Show Regions
          </button>
        </ng-template>
      </div>

      <div class="flex-grow-1 overflow-auto">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="layers-tree">
          <mat-tree-node *matTreeNodeDef="let node"
                         (click)="mapService.selectedTreeNodes.select(node)"
                         [class.bg-info]="mapService.selectedTreeNodes.isSelected(node)"
                         class="align-items-stretch">
            <div class="position-relative hstack flex-fill overflow-auto">

              <div class="d-flex flex-row align-items-center me-auto">
                <button mat-icon-button disabled></button>

                <ng-template [ngIf]="node.displaying" [ngIfElse]="showLayerButton">
                  <button mat-icon-button (click)="onHideMapTreeNodeClicked(node)"
                          title="Hide Layer">
                    <mat-icon>visibility</mat-icon>
                  </button>
                </ng-template>
                <ng-template #showLayerButton>
                  <button mat-icon-button (click)="onShowLayerButtonClicked(node)" title="Show Layer">
                    <mat-icon>visibility_off</mat-icon>
                  </button>
                </ng-template>

                <ng-container *ngIf="node | instanceof:Region">
                  <button mat-icon-button (click)="onSmoothRegionButtonClicked(node)"
                          title="Smooth">
                    <mat-icon>panorama_wide_angle</mat-icon>
                  </button>
                </ng-container>

                <ng-container *ngIf="node | instanceof:Image">
                  <img [src]="node.layer._url" [alt]="node.name" class="img-thumbnail rounded-0"/>
                </ng-container>

                <div class="px-1">{{ node.name }}</div>
              </div>

              <button mat-icon-button [matMenuTriggerFor]="optionsMenu">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #optionsMenu="matMenu">
                <ng-container *ngIf="node | instanceof:Region">
                  <button mat-menu-item (click)="onButtonCopyClicked(node)">
                    <mat-icon>file_copy</mat-icon>
                    <span>Duplicate</span>
                  </button>
                </ng-container>
                <button mat-menu-item (click)="onRenameButtonClicked(node)">
                  <mat-icon>edit</mat-icon>
                  <span>Rename</span>
                </button>
                <button mat-menu-item (click)="onDeleteTreeNodeButtonClicked(node)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="onExportTreeNodeButtonClicked(node)">
                  <mat-icon>language</mat-icon>
                  <span>Export To GeoJSON...</span>
                </button>
              </mat-menu>
            </div>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node align-items-stretch"
                 (click)="mapService.selectedTreeNodes.select(node)"
                 [class.bg-info]="mapService.selectedTreeNodes.isSelected(node)">
              <div class="position-relative hstack flex-fill overflow-auto">
                <div class="d-flex flex-row align-items-center me-auto">
                  <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                    </mat-icon>
                  </button>

                  <ng-template [ngIf]="node.displaying" [ngIfElse]="showLayerButton">
                    <button mat-icon-button (click)="onHideMapTreeNodeClicked(node)"
                            title="Hide Layer">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </ng-template>
                  <ng-template #showLayerButton>
                    <button mat-icon-button (click)="onShowLayerButtonClicked(node)"
                            title="Show Layer">
                      <mat-icon>visibility_off</mat-icon>
                    </button>
                  </ng-template>

                  {{ node.name }}
                </div>

                <button mat-icon-button [matMenuTriggerFor]="expandableNodeOptionsMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #expandableNodeOptionsMenu="matMenu">
                  <button mat-menu-item (click)="onRenameButtonClicked(node)">
                    <mat-icon>edit</mat-icon>
                    <span>Rename</span>
                  </button>
                  <button mat-menu-item (click)="onDeleteTreeNodeButtonClicked(node)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="onExportTreeNodeButtonClicked(node)">
                    <mat-icon>language</mat-icon>
                    <span>Export To GeoJSON...</span>
                  </button>
                </mat-menu>

              </div>
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [hidden]="!treeControl.isExpanded(node)" role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>

      <div class="d-flex flex-wrap justify-content-end bg-light border-top">
        <button mat-button (click)="onCreateNewLayerButtonClicked()" title="Create New Layer">
          <mat-icon>create_new_folder</mat-icon>
          New Layer
        </button>
      </div>
    </div>
  </main>

</div>
