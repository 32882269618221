import {Injectable} from '@angular/core';
import {CsvParser} from '../../shared/csv-parser.service';
import {BehaviorSubject} from 'rxjs';
import {isNumeric} from 'rxjs/internal-compatibility';
import {ParsedCsvFile} from '../../shared/csv-file-preview-dialog/csv-file-preview-dialog.component';
import {filter, map, pairwise, startWith} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CsvFileService {

    private readonly parsedCsvFiles = new BehaviorSubject<Array<ParsedCsvFile>>([]);
    public readonly parsedCsvFiles$ = this.parsedCsvFiles.asObservable();
    public readonly firstCsvFileImported$ = this.parsedCsvFiles$.pipe(
        map(selectionChange =>  !!selectionChange.length),
        startWith(!!this.parsedCsvFiles.getValue().length),
        pairwise(),
        filter(([previous, current]) => !previous && current)
    )

    constructor(private csvParser: CsvParser) {
    }

    private static containsHeader(content: Array<Array<any>>): boolean {

        return content.find(Boolean)?.every(cell => !isNumeric(cell));
    }

    public addFile(csvFile: { name: string; content: string | ArrayBuffer }): void {

        const csvRows: Array<Array<any>> = this.csvParser.parse(csvFile.content);

        const updatedCsvFiles = this.parsedCsvFiles.getValue().concat({
            name: csvFile.name,
            headers: CsvFileService.containsHeader(csvRows) ? csvRows.shift() : null,
            csvRows
        });

        this.parsedCsvFiles.next(updatedCsvFiles);
    }

    public removeParsedCsvFile(parsedCsvFile: ParsedCsvFile): void {

        const updatedCsvFiles = this.parsedCsvFiles.getValue().filter(csvFile => csvFile !== parsedCsvFile);

        this.parsedCsvFiles.next(updatedCsvFiles);
    }
}
