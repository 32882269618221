import {DatasetTypedExportDto} from '../../models/export/dataset-typed-export-dto';
import {DatasetLayer} from '../../models/dataset-layer';
import {RegionExportDto} from '../../models/export/region-export-dto';
import {ImageExportDto} from '../../models/export/image-export-dto';
import {Image} from '../../models/image';
import {DistortableImageOverlayBuilder} from '../builder/distortable-image-overlay-builder';
import {Type} from '../../models/export/typed-export-dto';
import {MapTreeNode} from '../../models/map-tree-node';
import {PointFeatureToCircleMarkerMapper, RegionExportDtoToRegionMapper} from './dataset-dto-to-dataset-mapper';

export class DatasetTypedExportDtoToDatasetMapper implements Mapper<DatasetTypedExportDto, DatasetLayer> {

  public map(datasetTypedExportDto: DatasetTypedExportDto): DatasetLayer {

    const birthLocations = datasetTypedExportDto.exportDto.birthLocations.map(feature =>
      new PointFeatureToCircleMarkerMapper().map(feature)
    );
    const dataset = new DatasetLayer(datasetTypedExportDto.exportDto.name, birthLocations, false);

    datasetTypedExportDto.childNodes
      .map(node => ExportDtoMapperFactory.for(node.type).map(node.exportDto))
      .forEach(node => dataset.addMapTreeNode(node));

    return dataset;
  }
}

class ExportDtoMapperFactory {

  public static for(type: Type): Mapper<RegionExportDto | ImageExportDto, MapTreeNode<any>> {

    switch (type) {
      case Type.REGION:
        return new RegionExportDtoToRegionMapper();
      case Type.IMAGE:
        return new ImageExportDtoToImageMapper();
    }
  }
}

class ImageExportDtoToImageMapper implements Mapper<ImageExportDto, Image> {

  public map(imageExportDto: ImageExportDto): Image {

    const imageOverlay = new DistortableImageOverlayBuilder()
      .setUrl(imageExportDto.img)
      .setCorners(imageExportDto.corners)
      .build();

    return new Image(imageExportDto.name, imageOverlay);
  }
}

export interface Mapper<IN, OUT> {

  map(input: IN): OUT;
}
