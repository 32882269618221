import {AbstractType, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'instanceof'
})
export class InstanceofPipe implements PipeTransform {

  public transform<V, R>(value: V, type: AbstractType<R>): R | undefined {

    return value instanceof type ? value : undefined;
  }
}
