import {Component, OnDestroy} from '@angular/core';
import {ResizeEvent} from 'angular-resizable-element';
import {CsvFileService} from './sidebar/csv-files-sidebar/csv-file.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    public leftSidebarStyle: object = {
        width: '460px'
    };
    public rightSidebarStyle: object = {
        width: '460px'
    };
    public csvFilesSidebarCollapsed = true;

    private opencsvFilesSidebarSubscription = this.csvFileService.firstCsvFileImported$.subscribe(() =>
        this.csvFilesSidebarCollapsed = false
    );

    constructor(public csvFileService: CsvFileService) {
    }

    ngOnDestroy(): void {

        this.opencsvFilesSidebarSubscription.unsubscribe();
    }

    public onLeftSidebarResizeEnd(event: ResizeEvent): void {

        this.leftSidebarStyle = {
            width: `${event.rectangle.width}px`
        };
    }

    public onRightSidebarResizeEnd(event: ResizeEvent): void {

        this.rightSidebarStyle = {
            width: `${event.rectangle.width}px`
        };
    }
}
