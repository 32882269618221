import {RegionExportDto, RegionTypedExportDto} from './region-export-dto';
import {Feature, Point} from 'geojson';
import {DatasetLayer} from '../dataset-layer';
import {Type, TypedExportDto} from './typed-export-dto';
import {Region} from '../region';
import {Image} from '../image';
import {Mapper} from '../../shared/mapper/dataset-typed-export-dto-to-dataset-mapper';
import {MapTreeNode} from '../map-tree-node';
import {ImageExportDto, ImageTypedExportDto} from './image-export-dto';

export class DatasetTypedExportDto extends TypedExportDto<DatasetExportDto> {

  constructor(public exportDto: DatasetExportDto,
              public childNodes: Array<TypedExportDto<any>>) {

    super(Type.DATASET, exportDto);
  }

  public static of(dataset: DatasetLayer): DatasetTypedExportDto {

    const datasetExportDto = DatasetExportDto.of(dataset);

    const childNodes = dataset.childNodes.map(node =>
      new MapTreeNodeToExportDtoMapper().map(node)
    );

    return new DatasetTypedExportDto(datasetExportDto, childNodes);
  }
}

class DatasetExportDto {

  constructor(public name: string, public birthLocations: Array<Feature<Point, any>>) {
  }

  public static of(dataset: DatasetLayer) {

    const circleMarkers = dataset.birthLocations.map(circleMarker => ({
      ...circleMarker.toGeoJSON(),
      properties: circleMarker.options
    }));

    return new DatasetExportDto(dataset.name, circleMarkers);
  }
}

class MapTreeNodeToExportDtoMapper implements Mapper<MapTreeNode<any>, TypedExportDto<any>> {

  public map(node: MapTreeNode<any>): TypedExportDto<any> {

    switch (node.constructor) {
      case Region: {

        return new RegionTypedExportDto(RegionExportDto.of(node as Region));
      }
      case Image: {

        return new ImageTypedExportDto(ImageExportDto.of(node as Image));
      }
    }
  }
}
