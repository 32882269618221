import {LatLng} from 'leaflet';
import {TypedExportDto, Type} from './typed-export-dto';
import {Image} from '../image';

export class ImageExportDto {

  constructor(public name: string, public img: string, public corners: LatLng[]) {
  }

  public static of(image: Image): ImageExportDto {

    // @ts-ignore
    return new ImageExportDto(image.name, image.layer._url, image.layer.getCorners());
  }
}

export class ImageTypedExportDto extends TypedExportDto<ImageExportDto> {

  constructor(exportDto: ImageExportDto) {

    super(Type.IMAGE, exportDto);
  }
}
